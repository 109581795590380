import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Usar o mesmo CSS para manter a consistência

const RecuperarSenha = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [erro, setErro] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [tokenEnviado, setTokenEnviado] = useState(false);
  const [tokenValido, setTokenValido] = useState(false);

  const handleEnviarToken = async () => {
    if (!email) {
      setErro('Por favor, insira um e-mail.');
      return;
    }

    try {
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
          },
        }
      );

      const usuarios = response.data.results;
      const usuarioExistente = usuarios.find(usuario => usuario['E-mail'] === email);

      if (!usuarioExistente) {
        setErro('Utilize o e-mail que você usou para comprar o produto.');
        return;
      }

      const tokenGerado = Math.floor(100000 + Math.random() * 900000).toString();

      try {
        await axios.post(process.env.REACT_APP_RECUPERAR_SENHA_N8N_WEBHOOK_URL, {
          nome: usuarioExistente['Nome'],
          numeroWhatsapp: usuarioExistente['Numero do Whatsapp'],
          token: tokenGerado,
        });

        await axios.patch(
          `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/${usuarioExistente.id}/?user_field_names=true`,
          {
            'Token_para_validar_recuperacao_senha': tokenGerado,
          },
          {
            headers: {
              Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
            },
          }
        );

        setMensagem('Token enviado para o seu WhatsApp.');
        setTokenEnviado(true);
      } catch (webhookError) {
        console.error('Erro ao enviar o token para o webhook:', webhookError);
        setErro('Ocorreu um erro ao enviar o token. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro ao verificar o e-mail no banco de dados:', error);
      setErro('Ocorreu um erro ao verificar o e-mail. Tente novamente mais tarde.');
    }
  };

  const handleValidarToken = async () => {
    try {
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
          },
        }
      );

      const usuarioExistente = response.data.results.find(usuario => usuario['E-mail'] === email);

      if (usuarioExistente && usuarioExistente['Token_para_validar_recuperacao_senha'] === token) {
        setTokenValido(true);
        setMensagem('Token validado com sucesso.');
      } else {
        setErro('Token inválido. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao validar o token:', error);
      setErro('Ocorreu um erro ao validar o token. Tente novamente mais tarde.');
    }
  };

  const handleRedefinirSenha = async () => {
    if (novaSenha !== confirmarNovaSenha) {
      setErro('As senhas não coincidem.');
      return;
    }

    try {
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
          },
        }
      );

      const usuarioExistente = response.data.results.find(usuario => usuario['E-mail'] === email);

      if (usuarioExistente) {
        await axios.patch(
          `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/${usuarioExistente.id}/?user_field_names=true`,
          {
            'senha': novaSenha, 
            'Token_para_validar_recuperacao_senha': '', 
          },
          {
            headers: {
              Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
            },
          }
        );

        setMensagem('Senha atualizada com sucesso!');
        setTimeout(() => navigate('/'), 3000); 
      } else {
        setErro('Erro ao atualizar a senha. Usuário não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao atualizar a senha:', error);
      setErro('Ocorreu um erro ao tentar atualizar a senha. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={require('../imagens/logo_menu_login.webp')} alt="Logo" className="login-logo" />
      </div>
      <div className="form-container">
        <div className="gradient-border"></div>
        <h1 className="gradient-text">Aplicativos para Prosperar</h1>
        <p className="login-page-description">Sua jornada para o sucesso começa aqui!</p>
        <h2>Recuperar Senha</h2>
        <p className="login-page-description">Preencha o formulário abaixo para redefinir sua senha.</p>
        
        {!tokenEnviado && (
          <>
            <div className="input-container">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button onClick={handleEnviarToken}>Enviar Token de Validação</button>
            <p className="login-page-description">O token será enviado para o seu WhatsApp.</p>
          </>
        )}

        {tokenEnviado && !tokenValido && (
          <>
            <div className="input-container">
              <input
                type="text"
                placeholder="Token de validação"
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
            </div>
            <button onClick={handleValidarToken}>Validar Token</button>
          </>
        )}

        {tokenValido && (
          <>
            <div className="input-container">
              <input
                type={mostrarSenha ? 'text' : 'password'}
                placeholder="Nova Senha"
                value={novaSenha}
                onChange={(e) => setNovaSenha(e.target.value)}
                className="password-input"
              />
              <span
                className="toggle-password"
                onClick={() => setMostrarSenha(!mostrarSenha)}
              >
                <i className={`fas ${mostrarSenha ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </span>
            </div>
            <div className="input-container">
              <input
                type={mostrarSenha ? 'text' : 'password'}
                placeholder="Confirme sua Nova Senha"
                value={confirmarNovaSenha}
                onChange={(e) => setConfirmarNovaSenha(e.target.value)}
                className="password-input"
              />
              <span
                className="toggle-password"
                onClick={() => setMostrarSenha(!mostrarSenha)}
              >
                <i className={`fas ${mostrarSenha ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </span>
            </div>
            <button onClick={handleRedefinirSenha}>Redefinir Senha</button>
          </>
        )}

        {erro && <p className="error">{erro}</p>}
        {mensagem && <p className="login-page-description">{mensagem}</p>}
        <button className="button-recuperar" onClick={() => navigate('/')}>Voltar para a tela de login</button>
      </div>
    </div>
  );
};

export default RecuperarSenha;
