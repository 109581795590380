import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft, FaTimes, FaChevronDown, FaRobot, FaMoneyBillWave, FaChartLine, FaChartBar, FaBullseye, FaPencilAlt, FaFileAlt, FaTools, FaMapMarkedAlt, FaMagic, FaBullhorn, FaRedo, FaCheckCircle } from 'react-icons/fa';
import './MenuVertical.css';
import PalavrasUsuario from './PalavrasUsuario';

const MenuVertical = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState({});

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleSubMenu = (menu) => {
        setIsSubMenuOpen((prevState) => ({
            ...prevState,
            [menu]: !prevState[menu],
        }));
    };

    const handleMouseEnter = (item) => {
        if (!isOpen) {
            setHoveredItem(item);
            setIsSubMenuOpen((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    };

    const handleMouseLeave = () => {
        if (!isOpen) {
            setHoveredItem(null);
            setIsSubMenuOpen({});
        }
    };

    return (
        <div className={`menu-vertical-container ${isOpen ? 'open' : 'closed'}`}>
            <div className="logo-section">
                <img 
                    src={require('../../imagens/logo_menu_login.webp')} 
                    alt="Logo" 
                    className={`menu-logo ${isOpen ? 'expanded' : 'collapsed'}`} 
                />
                <button className="menu-toggle" onClick={toggleMenu}>
                    {isOpen ? <FaTimes /> : <FaChevronLeft />}
                </button>
            </div>
            <nav className="menu-vertical-nav">
                <ul className="menu-vertical-list">
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('assistente-ai')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="menu-vertical-link" onClick={() => toggleSubMenu('assistente-ai')}>
                            <FaRobot className="menu-vertical-icon" />
                            {isOpen && <span>Assistente AI</span>}
                            {!isOpen && hoveredItem === 'assistente-ai' && <span className="tooltip">Assistente AI</span>}
                            {isOpen && <FaChevronDown className="submenu-icon" />}
                        </div>
                        {isSubMenuOpen['assistente-ai'] && (
                            <ul className="submenu-list">
                                <li className="submenu-item">
                                    <div className="menu-vertical-link" onClick={() => toggleSubMenu('financeiro')}>
                                        <FaMoneyBillWave className="menu-vertical-icon" />
                                        <span>Financeiro</span>
                                        <FaChevronDown className="submenu-icon" />
                                    </div>
                                    {isSubMenuOpen['financeiro'] && (
                                        <ul className="submenu-list">
                                            <li className="submenu-item">
                                                <Link to="/lucro-alvo" className="submenu-link">
                                                    <FaBullseye className="unique-submenu-icon" />
                                                    <span className="submenu-title">Meta de Lucro</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/planejador-prosperidade" className="submenu-link">
                                                    <FaChartLine className="unique-submenu-icon" />
                                                    <span className="submenu-title">Guia de Investimento Fácil</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/conversao-magica" className="submenu-link">
                                                    <FaMagic className="unique-submenu-icon" />
                                                    <span className="submenu-title">Conversão Mágica</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/funil-de-vendas" className="submenu-link">
                                                    <FaChartBar className="unique-submenu-icon" />
                                                    <span className="submenu-title">Funil de Vendas</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/analise-metricas-meta" className="submenu-link">
                                                    <FaChartBar className="unique-submenu-icon" />
                                                    <span className="submenu-title">Análise de Métricas Meta ADS</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li className="submenu-item">
                                    <div className="menu-vertical-link" onClick={() => toggleSubMenu('gerador-conversao')}>
                                        <FaBullhorn className="menu-vertical-icon" />
                                        <span>Gerador de Conversão</span>
                                        <FaChevronDown className="submenu-icon" />
                                    </div>
                                    {isSubMenuOpen['gerador-conversao'] && (
                                        <ul className="submenu-list">
                                            <li className="submenu-item">
                                                <Link to="/criador-de-textos-persuasivos" className="submenu-link">
                                                    <FaBullhorn className="unique-submenu-icon" />
                                                    <span className="submenu-title">Copy para Meta ADS</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/script-vendas-whatsapp" className="submenu-link">
                                                    <FaPencilAlt className="unique-submenu-icon" />
                                                    <span className="submenu-title">Script de Vendas para WhatsApp</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/remarketing" className="submenu-link">
                                                    <FaRedo className="unique-submenu-icon" />
                                                    <span className="submenu-title">Remarketing</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/headlines" className="submenu-link">
                                                    <FaFileAlt className="unique-submenu-icon" />
                                                    <span className="submenu-title">Headlines</span>
                                                </Link>
                                            </li>
                                            <li className="submenu-item">
                                                <Link to="/prova" className="submenu-link">
                                                    <FaCheckCircle className="unique-submenu-icon" />
                                                    <span className="submenu-title">Prova</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </li>
                                <li className="submenu-item">
                                    <Link to="/estudio-criacao" className="submenu-link">
                                        <FaTools className="unique-submenu-icon" />
                                        <span className="submenu-title">Estúdio de Criação</span>
                                    </Link>
                                </li>
                                <li className="submenu-item">
                                    <Link to="/mapa-digital" className="submenu-link">
                                        <FaMapMarkedAlt className="unique-submenu-icon" />
                                        <span className="submenu-title">Mapa do Digital</span>
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('tutoriais')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/tutoriais" className="menu-vertical-link">
                            <i className="menu-vertical-icon fa fa-video"></i>
                            {isOpen && <span>Tutoriais</span>}
                            {!isOpen && hoveredItem === 'tutoriais' && <span className="tooltip">Tutoriais</span>}
                        </Link>
                    </li>
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('suporte')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/suporte" className="menu-vertical-link">
                            <i className="menu-vertical-icon fa fa-life-ring"></i>
                            {isOpen && <span>Suporte</span>}
                            {!isOpen && hoveredItem === 'suporte' && <span className="tooltip">Suporte</span>}
                        </Link>
                    </li>
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('favoritos')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/favoritos" className="menu-vertical-link">
                            <i className="menu-vertical-icon fa fa-star"></i>
                            {isOpen && <span>Favoritos</span>}
                            {!isOpen && hoveredItem === 'favoritos' && <span className="tooltip">Favoritos</span>}
                        </Link>
                    </li>
                    <li 
                        className="menu-vertical-item"
                        onMouseEnter={() => handleMouseEnter('minha-conta')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Link to="/minha-conta" className="menu-vertical-link">
                            <i className="menu-vertical-icon fa fa-user"></i>
                            {isOpen && <span>Minha Conta</span>}
                            {!isOpen && hoveredItem === 'minha-conta' && <span className="tooltip">Minha Conta</span>}
                        </Link>
                    </li>
                </ul>
                {isOpen && <PalavrasUsuario />}
            </nav>
        </div>
    );
};

export default MenuVertical;