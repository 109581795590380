import React, { useEffect } from 'react';
import './FacebookLogin.css'; // CSS a ser substituído
import { isAuthenticated, getUserEmail } from '../../auth/auth';
import { useNavigate } from 'react-router-dom';

const FacebookLogin = ({ onLoginSuccess }) => {
  const navigate = useNavigate();
  const userEmail = getUserEmail(); // Obtém o e-mail do usuário logado

  useEffect(() => {
    // Inicializar o SDK do Facebook
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v20.0',
      });
    };

    // Carregar o SDK do Facebook
    (function (d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  const handleLogin = () => {
    window.FB.login(function (response) {
      if (response.authResponse) {
        console.log('Logged in', response);

        // Verifica se o usuário está autenticado no sistema
        if (!isAuthenticated()) {
          alert('Usuário não autenticado no sistema. Faça login primeiro.');
          navigate('/login');
          return;
        }

        // Verifica se o e-mail do usuário está disponível
        if (!userEmail) {
          alert('Não foi possível obter o e-mail do usuário.');
          return;
        }

        try {
          // Armazenar o token do Facebook no sessionStorage, associado ao e-mail do usuário logado
          sessionStorage.setItem(`facebookToken_${userEmail}`, response.authResponse.accessToken);
          
          // Chama a função para buscar os dados
          onLoginSuccess(response.authResponse.accessToken);
        } catch (error) {
          console.error('Erro ao armazenar o token no sessionStorage:', error);
          alert('Ocorreu um erro ao armazenar suas credenciais. Por favor, tente novamente.');
        }
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'public_profile,email,ads_management' });
  };

  return (
    <div className="facebook-login-container">
      <button className="facebook-login-button" onClick={handleLogin}>
        <i className="fab fa-facebook-f"></i> Login com Facebook
      </button>
    </div>
  );
};

export default FacebookLogin;
