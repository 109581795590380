import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './CriadorDeTextosPersuasivos.css';
import logo from '../../imagens/logo_menu_login.webp';
import { isAuthenticated, logout } from '../../auth/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Chat from './Chat-copy.js'; // Importando o componente Chat
import BlocoDeInformacoes from './BlocoDeInformacoes'; // Importando o componente BlocoDeInformacoes

const CriadorDeTextosPersuasivos = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redirecionamento utilizando useNavigate
    }
  }, [navigate]);

  return (
    <div className="criador-de-textos-container">
      <div className="criador-de-textos-header">
        <img src={logo} alt="Logo" className="criador-de-textos-logo" />
        <div className="criador-de-textos-user-info">
          <span>{localStorage.getItem('userEmail')}</span>
          <button className="criador-de-textos-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="criador-de-textos-content">
        <h1 className="criador-de-textos-title-gradient">Copy para Prosperar</h1>
        <p className="criador-de-textos-description">
          Eu sou um agente especializado em texto persuasivo e vendas online. Vamos criar juntos uma copy irresistível para seu anúncio no Facebook e um script de vendas eficaz para o WhatsApp. Meu objetivo é ajudar você a vender muito e prosperar no mundo digital.
        </p>
        <Link to="/menu" className="criador-de-textos-button-menu">
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>
        <div className="criador-de-textos-step">
          <h2>Como Acessar e Utilizar o "Copy para Prosperar":</h2>
          <ol>
            <li><strong>1. Acesse o ChatGPT:</strong> Visite o site do ChatGPT e faça login na sua conta. Se ainda não tiver uma conta, <a href="https://chatgpt.co" className="criador-de-textos-link" target="_blank" rel="noopener noreferrer">crie uma gratuitamente</a>.</li>
            <li><strong>2. Fixe o Robô:</strong> Após acessar, busque por "Copy para Prosperar" e fixe o robô para fácil acesso.</li>
            <li><strong>3. Selecione o Tipo de Copy:</strong> O robô perguntará se deseja criar uma copy para anúncios no Facebook ou um script de vendas para o WhatsApp.</li>
            <li><strong>4. Forneça Informações:</strong> Responda às perguntas sobre o produto, público-alvo, benefícios e detalhes de pagamento e entrega.</li>
            <li><strong>5. Receba sua Copy:</strong> Após fornecer as informações, o robô gerará uma copy ou script de vendas personalizado.</li>
          </ol>
        </div>
        <div className="criador-de-textos-menu">
          <h3>Menu de Opções do Robô:</h3>
          <ul>
            <li><FontAwesomeIcon icon={faExternalLinkAlt} /> <strong>Criar uma nova copy:</strong> Inicie uma nova criação de texto do zero.</li>
            <li><FontAwesomeIcon icon={faExternalLinkAlt} /> <strong>Melhorar uma copy existente:</strong> Otimize um texto já criado.</li>
            <li><FontAwesomeIcon icon={faExternalLinkAlt} /> <strong>Informar a estrutura de copy utilizada:</strong> Detalhe sobre a copy que foi usada.</li>
            <li><FontAwesomeIcon icon={faExternalLinkAlt} /> <strong>Criar nova copy com outra base de conhecimento:</strong> Utilize diferentes dados e informações para criar uma nova copy.</li>
            <li><FontAwesomeIcon icon={faExternalLinkAlt} /> <strong>Avaliar a copy criada:</strong> Dê uma nota de 0 a 10 para ajudar na melhoria contínua do serviço.</li>
          </ul>
        </div>
        <a href="https://chatgpt.com/g/g-fs4S3q1C3-copy-para-prosperar" className="criador-de-textos-button-large" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faExternalLinkAlt} /> Acesse Copy para Prosperar
        </a>
        
        {/* Chat incluído abaixo do botão */}
        <Chat />

        {/* Bloco de Informações renderizado abaixo do Chat */}
        <BlocoDeInformacoes />
      </div>
    </div>
  );
};

export default CriadorDeTextosPersuasivos;
