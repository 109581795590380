// src/components/Chat.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat.css';
import { FaTrash, FaPlusCircle, FaPaperPlane, FaUserCircle, FaDownload, FaChartLine, FaTimes } from 'react-icons/fa'; 
import { getUserEmail } from '../../auth/auth';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { inicializarPalavras, consumirPalavras, verificarPalavras } from '../../auth/palavraControle';
import { startMonitoring, stopMonitoring } from '../../auth/userActivityMonitor'; 
import { startSync, stopSync } from '../../auth/syncWords';

const Chat = ({ metricsData }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isTyping, setIsTyping] = useState(false); 
  const [chatBloqueado, setChatBloqueado] = useState(false); // Estado para controlar o bloqueio do chat
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  // Estados para a análise de métricas
  const [saleValue, setSaleValue] = useState('');
  const [commissionValue, setCommissionValue] = useState('');
  const [isMetricsPanelVisible, setIsMetricsPanelVisible] = useState(true); // Controle de visibilidade do painel de métricas

  const userEmail = getUserEmail(); 
  const MAX_REQUESTS_PER_MINUTE = 10;

  useEffect(() => {
    const storedMessages = localStorage.getItem(`chatHistory_${userEmail}`);
    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`);
    
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }

    // Sempre que abrir o chat, verificar se o saldo é zero e bloquear o chat se necessário
    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
    } else {
      setChatBloqueado(false); // Desbloquear se houver saldo
    }

    startMonitoring(); // Inicia o monitoramento da atividade do usuário
    startSync(); // Inicia a sincronização periódica das palavras
    scrollToBottom();

    return () => {
      stopMonitoring(); // Para o monitoramento da atividade do usuário ao desmontar o componente
      stopSync(); // Para a sincronização periódica das palavras ao desmontar o componente
    };
  }, [userEmail]);

  useEffect(() => {
    const textarea = inputRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setCharCount(textarea.value.length);
  }, [input]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const saveMessages = (messages) => {
    localStorage.setItem(`chatHistory_${userEmail}`, JSON.stringify(messages));
    scrollToBottom();
  };

  const canMakeRequest = () => {
    const requestTimestamps = JSON.parse(localStorage.getItem(`requestTimestamps_${userEmail}`)) || [];
    const now = Date.now();
    const oneMinuteAgo = now - 60000;

    const recentRequests = requestTimestamps.filter(timestamp => timestamp > oneMinuteAgo);
    localStorage.setItem(`requestTimestamps_${userEmail}`, JSON.stringify(recentRequests));

    return recentRequests.length < MAX_REQUESTS_PER_MINUTE;
  };

  const recordRequestTimestamp = () => {
    const requestTimestamps = JSON.parse(localStorage.getItem(`requestTimestamps_${userEmail}`)) || [];
    requestTimestamps.push(Date.now());
    localStorage.setItem(`requestTimestamps_${userEmail}`, JSON.stringify(requestTimestamps));
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  /**
   * Função para atribuir peso aos tokens com base no comprimento da palavra.
   */
  const contarTokensPorPalavra = (palavra) => {
    const length = palavra.length;
    if (length <= 4) return 1;
    if (length <= 8) return 2;
    return 3;
  };

  /**
   * Função para calcular o total de tokens usados em uma mensagem.
   * Utiliza a lógica de peso para cada palavra.
   */
  const calcularTokensUsados = (message) => {
    return message
      .trim()
      .split(/\s+/)
      .reduce((total, palavra) => total + contarTokensPorPalavra(palavra), 0);
  };

  // Função para verificar o saldo de palavras diretamente do localStorage para o e-mail específico
  const verificarSaldoLocalStorage = () => {
    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`); // Consulta agora com base no e-mail específico
    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
      return false; // Não permite o uso do chat
    }
    return true;
  };

  const handleSend = async (messageToSend = null) => {
    const message = messageToSend || input;

    if (message.trim() === '') return;

    // Calcular tokens usados com base no peso das palavras
    const tokensUsados = calcularTokensUsados(message);

    // Verificar saldo de palavras no cache
    if (!verificarSaldoLocalStorage()) {
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      return; // Bloqueia o envio de novas mensagens
    }

    let saldoAtual = parseInt(localStorage.getItem(`${userEmail}-palavrasDisponiveis`), 10);
    if (isNaN(saldoAtual)) saldoAtual = 0;

    if (saldoAtual < tokensUsados) {
      alert('Você não tem tokens suficientes para enviar essa mensagem.');
      return;
    }

    if (!canMakeRequest()) {
      alert('Você atingiu o limite de requisições por minuto. Por favor, aguarde.');
      return;
    }

    const userMessage = { text: message, sender: 'user' };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    saveMessages(updatedMessages);

    // Atualizar o saldo localmente e no servidor
    saldoAtual -= tokensUsados;
    localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual);
    await consumirPalavras(userEmail, tokensUsados);

    // Se o saldo chegar a zero após o envio da mensagem, bloquear o chat
    if (saldoAtual <= 0) {
      setChatBloqueado(true);
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
    }

    setIsTyping(true);
    recordRequestTimestamp();

    try {
      let threadId = localStorage.getItem(`chatThreadId_${userEmail}`);

      if (!threadId) {
        const threadResponse = await axios.post(
          'https://api.openai.com/v1/threads',
          {},
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
        threadId = threadResponse.data.id;
        localStorage.setItem(`chatThreadId_${userEmail}`, threadId);
      }

      await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          role: 'user',
          content: message
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      setInput('');

      const runResponse = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: process.env.REACT_APP_OPENAI_ASSISTANT_ID
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      let runStatus = 'queued';
      while (runStatus !== 'completed') {
        await new Promise(resolve => setTimeout(resolve, 2000));
        const statusResponse = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runResponse.data.id}`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
        runStatus = statusResponse.data.status;
      }

      const messagesResponse = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      const assistantMessage = messagesResponse.data.data.find(
        msg => msg.role === 'assistant'
      );

      setIsTyping(false);

      if (assistantMessage) {
        const assistantText = assistantMessage.content
          .map(content => content.text?.value || '')
          .join(' ');

        const updatedMessagesWithResponse = [
          ...updatedMessages,
          { text: assistantText, sender: 'assistant' }
        ];
        setMessages(updatedMessagesWithResponse);
        saveMessages(updatedMessagesWithResponse);

        // Calcular tokens usados na resposta do assistente
        const tokensResposta = calcularTokensUsados(assistantText);
        saldoAtual -= tokensResposta;
        localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual);
        await consumirPalavras(userEmail, tokensResposta);

        // Se o saldo chegar a zero após a resposta do assistente, bloquear o chat
        if (saldoAtual <= 0) {
          setChatBloqueado(true);
          alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
        }

      } else {
        const errorMessage = [
          ...updatedMessages,
          { text: 'Erro ao processar a solicitação.', sender: 'assistant' }
        ];
        setMessages(errorMessage);
        saveMessages(errorMessage);
      }

    } catch (error) {
      console.error('Erro ao comunicar com o assistente:', error.response ? error.response.data : error.message);
      const errorMessage = [
        ...messages,
        { text: 'Erro ao processar a solicitação.', sender: 'assistant' }
      ];
      setMessages(errorMessage);
      saveMessages(errorMessage);
    }
  };

  const handleDeleteConversation = async () => {
    const threadId = localStorage.getItem(`chatThreadId_${userEmail}`);

    if (threadId) {
      try {
        await axios.delete(
          `https://api.openai.com/v1/threads/${threadId}`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
      } catch (error) {
        console.error('Erro ao excluir a thread no servidor:', error.response ? error.response.data : error.message);
      }
    }

    // Remover apenas o histórico de conversa e o ID da thread
    localStorage.removeItem(`chatHistory_${userEmail}`);
    localStorage.removeItem(`chatThreadId_${userEmail}`);
    setMessages([]);
  };

  const handleNewConversation = () => {
    handleDeleteConversation();
  };

  const handleDownloadDocx = (message) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: message,
                  font: isMobile() ? 'Arial' : 'Calibri',
                  size: isMobile() ? 18 : 24,
                  break: 1,
                }),
              ],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "chat-response.docx");
    });
  };

  // Função para lidar com a análise de métricas
  const handleAnalyzeMetrics = () => {
    if (!metricsData || !metricsData.metrics || metricsData.metrics.length === 0) {
      alert('Nenhuma métrica disponível para análise.');
      return;
    }

    // Validação dos valores inseridos
    if (parseFloat(saleValue) <= 0 || parseFloat(commissionValue) <= 0) {
      alert('Os valores de venda e comissão devem ser positivos.');
      return;
    }

    // Formatar as métricas em uma mensagem compreensível
    const formattedMetrics = formatMetrics(metricsData);

    // Criar a mensagem que será enviada ao assistente
    const analysisMessage = `
      Aqui estão as métricas da campanha selecionada:
      - Campanha: ${metricsData.campaign.name}
      - Conjunto de Anúncios: ${metricsData.adSet.name}
      - Anúncio: ${metricsData.ad.name}
      - Objetivo da Campanha: ${metricsData.campaignObjective || 'Não especificado'}
      - Tipo de Conversão: ${metricsData.conversionType || 'Não especificado'}
      - Tipo de Criativo: ${metricsData.creativeType || 'Não especificado'}
      - Tipo de Campanha: ${metricsData.campaignType || 'Não especificado'}
      
      **Métricas:**
      ${formattedMetrics}

      **Valores:**
      - Valor de Venda: R$ ${parseFloat(saleValue).toFixed(2)}
      - Valor de Comissão: R$ ${parseFloat(commissionValue).toFixed(2)}
      
      Por favor, analise essas métricas e valores.
    `;

    // Enviar a mensagem para o chat
    handleSend(analysisMessage);

    // Limpar os campos após o envio
    setSaleValue('');
    setCommissionValue('');
  };

  // Função para formatar as métricas em texto
  const formatMetrics = (metricsData) => {
    const metrics = metricsData.metrics;
    const campaignMetrics = metricsData.campaignMetrics || {};

    if (!metrics || metrics.length === 0) return 'Nenhuma métrica disponível.';

    return metrics.map((metric, index) => {
      // Garantir que as métricas existam e sejam números
      const impressions = Number(metric.impressions) || 0;
      const reach = Number(metric.reach) || 0;
      const frequency = Number(metric.frequency) || 0;
      const spend = Number(metric.spend) || 0;
      const cpm = Number(metric.cpm) || 0;
      const inline_link_clicks = Number(metric.inline_link_clicks) || 0;

      // Usar link_click_ctr e link_click_cpc diretamente, sem cálculos adicionais
      const link_click_ctr = metric.link_click_ctr ? parseFloat(metric.link_click_ctr).toFixed(2) : '0.00';
      const link_click_cpc = metric.link_click_cpc ? parseFloat(metric.link_click_cpc).toFixed(2) : '0.00';

      // Usar totalResults e costPerResult diretamente
      const totalResults = Number(metric.totalResults) || 0;
      const costPerResult = metric.costPerResult ? parseFloat(metric.costPerResult).toFixed(2) : '0.00';

      // Usar roi, hookRate e holdRate
      const roi = metric.roi ? parseFloat(metric.roi.replace('%', '')).toFixed(2) : '0.00';
      const hookRate = metric.hookRate ? parseFloat(metric.hookRate).toFixed(2) : '0.00';
      const holdRate = metric.holdRate ? parseFloat(metric.holdRate).toFixed(2) : '0.00';

      return `**Métrica ${index + 1}:**
- Impressões: ${impressions}
- Alcance: ${reach}
- Frequência: ${frequency}
- Valor Usado: R$ ${spend.toFixed(2)}
- CPM: R$ ${cpm.toFixed(2)}
- CPC de Cliques no Link: R$ ${link_click_cpc}
- CTR de Cliques no Link: ${link_click_ctr}%
- Cliques no Link: ${inline_link_clicks}
- Resultados: ${totalResults}
- Custo por Resultado: R$ ${costPerResult}
- ROI: ${roi}%
- Hook Rate: ${hookRate}%
- Hold Rate: ${holdRate}%
`;
    }).join('\n');
  };

  // Condição ajustada para desabilitar o botão
  const isAnalyzeButtonDisabled = !(parseFloat(saleValue) > 0 && parseFloat(commissionValue) > 0 && metricsData && metricsData.metrics && metricsData.metrics.length > 0);

  return (
    <div className="unique-chat-container">
      <div className="unique-chat-header">
        <div className="unique-chat-logo"></div>
        <h2>A Nossa [IA] vai analisar suas métricas</h2>
        <div className="unique-chat-buttons">
          <div className="unique-chat-button" title="Nova Conversa">
            <FaPlusCircle onClick={handleNewConversation} />
          </div>
          <div className="unique-chat-button" title="Excluir Conversa">
            <FaTrash onClick={handleDeleteConversation} />
          </div>
        </div>
      </div>
      <div className="unique-chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`unique-chat-message ${msg.sender}`}>
            {msg.sender === 'user' ? <FaUserCircle className="unique-message-icon" /> : <div className="unique-message-icon"></div>}
            <span>{msg.text}</span>
            {msg.sender === 'assistant' && (
              <button className="unique-chat-download-button" onClick={() => handleDownloadDocx(msg.text)}>
                <FaDownload />
              </button>
            )}
          </div>
        ))}
        {isTyping && (
          <div className="unique-chat-message assistant typing">
            <div className="unique-message-icon"></div>
            <span>O assistente está digitando...</span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="unique-chat-input-container">
        <textarea
          ref={inputRef}
          className="unique-chat-text-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
          placeholder="Digite sua mensagem..."
          disabled={chatBloqueado} // Desabilita o campo se o chat estiver bloqueado
        />
        <span className="char-count">{charCount}</span>
        <button className="unique-chat-send-button" onClick={() => handleSend()} disabled={chatBloqueado}>
          <FaPaperPlane />
        </button>
      </div>

      {/* Botão Flutuante para Análise de Métricas */}
      {isMetricsPanelVisible && (
        <div className="floating-button-container">
          <button
            className="floating-close-button"
            onClick={() => setIsMetricsPanelVisible(false)}
            title="Fechar"
          >
            <FaTimes />
          </button>
          <input
            type="number"
            placeholder="Valor de Venda (R$)"
            value={saleValue}
            onChange={(e) => setSaleValue(e.target.value)}
            className="floating-input"
          />
          <input
            type="number"
            placeholder="Valor de Comissão (R$)"
            value={commissionValue}
            onChange={(e) => setCommissionValue(e.target.value)}
            className="floating-input"
          />
          <button
            onClick={handleAnalyzeMetrics}
            disabled={isAnalyzeButtonDisabled}
            className="floating-button"
            title="Análise de Métrica"
          >
            <FaChartLine /> Análise de Métrica
          </button>
        </div>
      )}
      {!isMetricsPanelVisible && (
        <button
          className="floating-open-button"
          onClick={() => setIsMetricsPanelVisible(true)}
          title="Abrir Análise de Métrica"
        >
          <FaChartLine />
        </button>
      )}
    </div>
  );
};

export default Chat;
