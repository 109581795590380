import React, { useState, useEffect } from 'react';
import './funil.css';
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChartBar, faMoneyBillWave, faUsers, faPercentage, faCartArrowDown, faQuestionCircle, faHandshake, faCheckCircle, faLightbulb, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';

const FunilVendas = () => {
  const userEmail = localStorage.getItem('userEmail');
  const [numVisitors, setNumVisitors] = useState('');
  const [commission, setCommission] = useState('');
  const navigate = useNavigate();

 useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  const calculateSales = (rate) => Math.floor(numVisitors * (rate / 100));
  const calculateRevenue = (rate) => calculateSales(rate) * commission;

  const clients10 = calculateSales(10);
  const clients5 = calculateSales(5);
  const clients3 = calculateSales(3);
  const revenue10 = calculateRevenue(10);
  const revenue5 = calculateRevenue(5);
  const revenue3 = calculateRevenue(3);

  return (
    <div className="funil-container">
      <div className="funil-header">
        <img src={logo} alt="Logo" className="funil-logo" />
        <div className="funil-user-info">
          <span>{userEmail}</span>
          <button className="funil-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="funil-content">
        <h1 className="funil-title-gradient">Funil de Vendas WhatsApp</h1>
        <Link to="/menu" className="funil-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>
        <p className="funil-description">
          Nosso aplicativo facilita o entendimento de como funciona um funil de vendas. Acompanhe a jornada do cliente, da captação ao fechamento, e veja como boas taxas de conversão no WhatsApp aumentam seu faturamento. Ideal para quem está começando no digital e quer dominar o processo de vendas.
        </p>
        <div className="funil-inputs">
          <label><FontAwesomeIcon icon={faUsers} /> Número de visitantes:</label>
          <input
            type="number"
            placeholder="Número de visitantes"
            value={numVisitors}
            onChange={(e) => setNumVisitors(e.target.value)}
          />
          <label><FontAwesomeIcon icon={faMoneyBillWave} /> Comissão por venda (R$):</label>
          <input
            type="number"
            placeholder="Comissão por venda (R$)"
            value={commission}
            onChange={(e) => setCommission(e.target.value)}
          />
        </div>
        <p className="illustration-note">Este funil é uma ilustração. O relatório final mostra cenários reais de conversão.</p>
        <div className="funil-stages">
          <div className="funil-stage stage-large" style={{ backgroundColor: '#4CAF50' }}>
            <h2><FontAwesomeIcon icon={faChartBar} /> Anúncio Facebook</h2>
            <p>Anúncio atrativo no Facebook com botão 'Enviar mensagem'</p>
            <span><FontAwesomeIcon icon={faUsers} /> {numVisitors} visitantes</span>
          </div>
          <div className="funil-stage stage-medium" style={{ backgroundColor: '#2196F3' }}>
            <h2><FontAwesomeIcon icon={faUsers} /> Boas-vindas no WhatsApp</h2>
            <p>Mensagem automática de boas-vindas e qualificação inicial</p>
            <span><FontAwesomeIcon icon={faUsers} /> {numVisitors} leads</span>
          </div>
          <div className="funil-stage stage-medium" style={{ backgroundColor: '#FF9800' }}>
            <h2><FontAwesomeIcon icon={faUsers} /> Apresentação do Produto</h2>
            <p>Envio de informações sobre o produto/serviço</p>
            <span><FontAwesomeIcon icon={faUsers} /> {Math.floor(numVisitors * 0.8)} interessados</span>
          </div>
          <div className="funil-stage stage-small" style={{ backgroundColor: '#FF5722' }}>
            <h2><FontAwesomeIcon icon={faUsers} /> Esclarecimento de Dúvidas</h2>
            <p>Responder perguntas e objeções dos clientes</p>
            <span><FontAwesomeIcon icon={faUsers} /> {Math.floor(numVisitors * 0.5)} qualificados</span>
          </div>
          <div className="funil-stage stage-smaller" style={{ backgroundColor: '#E91E63' }}>
            <h2><FontAwesomeIcon icon={faUsers} /> Oferta e Negociação</h2>
            <p>Apresentar oferta e negociar condições</p>
            <span><FontAwesomeIcon icon={faUsers} /> {Math.floor(numVisitors * 0.3)} ofertas feitas</span>
          </div>
          <div className="funil-stage stage-smallest" style={{ backgroundColor: '#9C27B0' }}>
            <h2><FontAwesomeIcon icon={faUsers} /> Fechamento</h2>
            <p>Finalizar a venda e orientar sobre próximos passos</p>
            <span><FontAwesomeIcon icon={faUsers} /> {Math.floor(numVisitors * 0.1)} clientes</span>
          </div>
        </div>
        <div className="funil-report">
          <h3>Relatório de Vendas</h3>
          <div className="report-section" style={{ backgroundColor: '#4CAF50' }}>
            <FontAwesomeIcon icon={faPercentage} className="report-section-icon" />
            <div>
              <h4>Conversão de WhatsApp: 10%</h4>
              <p>{clients10} vendas</p>
              <p>Receita: R$ {revenue10.toFixed(2)}</p>
            </div>
          </div>
          <div className="report-section" style={{ backgroundColor: '#2196F3' }}>
            <FontAwesomeIcon icon={faPercentage} className="report-section-icon" />
            <div>
              <h4>Conversão de WhatsApp: 5%</h4>
              <p>{clients5} vendas</p>
              <p>Receita: R$ {revenue5.toFixed(2)}</p>
            </div>
          </div>
          <div className="report-section" style={{ backgroundColor: '#FF9800' }}>
            <FontAwesomeIcon icon={faPercentage} className="report-section-icon" />
            <div>
              <h4>Conversão de WhatsApp: 3%</h4>
              <p>{clients3} vendas</p>
              <p>Receita: R$ {revenue3.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="funil-notes">
          <h3>Explicações sobre as Fases do Funil</h3>
          <div className="note-section">
            <FontAwesomeIcon icon={faLightbulb} className="note-icon" />
            <div>
              <h4>Anúncio Facebook</h4>
              <p>O anúncio no Facebook é a primeira etapa do funil, responsável por atrair visitantes para o seu WhatsApp. Utilize imagens atraentes e chamadas para ação claras para aumentar o número de visitantes.</p>
            </div>
          </div>
          <div className="note-section">
            <FontAwesomeIcon icon={faHandPointRight} className="note-icon" />
            <div>
              <h4>Boas-vindas no WhatsApp</h4>
              <p>Essa fase envolve o envio de mensagens automáticas de boas-vindas, apresentando o seu produto ou serviço e qualificando os leads. Uma boa comunicação inicial pode aumentar o interesse e a retenção dos visitantes.</p>
            </div>
          </div>
          <div className="note-section">
            <FontAwesomeIcon icon={faCartArrowDown} className="note-icon" />
            <div>
              <h4>Apresentação do Produto</h4>
              <p>Nesta etapa, você deve fornecer informações detalhadas sobre o produto/serviço. Ofereça conteúdo que responda às possíveis perguntas dos clientes e demonstre o valor do que você está vendendo.</p>
            </div>
          </div>
          <div className="note-section">
            <FontAwesomeIcon icon={faQuestionCircle} className="note-icon" />
            <div>
              <h4>Esclarecimento de Dúvidas</h4>
              <p>Responder às dúvidas e objeções é crucial para mover os leads para a próxima fase do funil. Estar preparado para fornecer informações adicionais e lidar com objeções pode melhorar significativamente a taxa de conversão.</p>
            </div>
          </div>
          <div className="note-section">
            <FontAwesomeIcon icon={faHandshake} className="note-icon" />
            <div>
              <h4>Oferta e Negociação</h4>
              <p>Apresentar uma oferta clara e negociar as condições de venda é essencial. Ofereça promoções, descontos ou garantias para incentivar a compra.</p>
            </div>
          </div>
          <div className="note-section">
            <FontAwesomeIcon icon={faCheckCircle} className="note-icon" />
            <div>
              <h4>Fechamento</h4>
              <p>Esta é a fase final, onde a venda é concluída. Certifique-se de que o processo de compra seja simples e claro. Ofereça um ótimo serviço de atendimento ao cliente para garantir a satisfação e fidelização dos clientes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunilVendas;
