import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Usar o mesmo CSS para manter a consistência

const Cadastro = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [numeroWhatsapp, setNumeroWhatsapp] = useState(''); // Número de WhatsApp sem o +55
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [erro, setErro] = useState('');

  const handleCadastro = async () => {
    if (nome === '' || email === '' || senha === '' || confirmarSenha === '' || numeroWhatsapp === '') {
      setErro('Por favor, preencha todos os campos.');
      return;
    }

    if (senha !== confirmarSenha) {
      setErro('As senhas não coincidem.');
      return;
    }

    // Validar número de WhatsApp removendo caracteres não numéricos
    const numeroSemCaracteresEspeciais = numeroWhatsapp.replace(/\D/g, '');

    if (!/^\d{10,11}$/.test(numeroSemCaracteresEspeciais)) {
      setErro('Por favor, insira um número de WhatsApp válido com DDD, sem espaços, traços ou parênteses.');
      return;
    }

    const numeroCompleto = `55${numeroSemCaracteresEspeciais}`;

    try {
      // Verificar se o email está na tabela Cadastro
      const responseCadastro = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_CADASTRO}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_CADASTRO}`,
          },
        }
      );

      const cadastros = responseCadastro.data.results;
      const cadastroExistente = cadastros.find(cadastro => cadastro['E-mail'] === email);

      if (cadastroExistente) {
        setErro('Email já cadastrado. Por favor, use um email diferente ou recupere sua senha.');
        return;
      }

      // Verificar se o email está na tabela Alunos
      const responseAlunos = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_ALUNOS}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_CADASTRO}`,
          },
        }
      );

      const alunos = responseAlunos.data.results;
      const alunoExistente = alunos.find(aluno => aluno.email === email);

      if (!alunoExistente) {
        setErro('Email não encontrado. Use o email que você utilizou na compra.');
        return;
      }

      // Se o email estiver na tabela Alunos e não estiver na tabela Cadastro, cadastrar na tabela Cadastro
      await axios.post(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_CADASTRO}/?user_field_names=true`,
        {
          'E-mail': email, // Nome do campo deve corresponder ao nome na tabela do Baserow
          'Nome': nome,    // Nome do campo deve corresponder ao nome na tabela do Baserow
          'senha': senha,   // Nome do campo deve corresponder ao nome na tabela do Baserow
          'Numero do Whatsapp': numeroCompleto, // Nome do campo na tabela do Baserow
        },
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_CADASTRO}`,
          },
        }
      );

      // Redirecionar para a tela de login após cadastro bem-sucedido
      navigate('/');
    } catch (error) {
      console.error('Erro ao cadastrar:', error);
      setErro('Ocorreu um erro ao tentar cadastrar. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={require('../imagens/logo_menu_login.webp')} alt="Logo" className="login-logo" />
      </div>
      <div className="form-container">
        <div className="gradient-border"></div>
        <h1 className="gradient-text">Aplicativos para Prosperar</h1>
        <p className="gradient-text">Sua jornada para o sucesso começa aqui!</p>
        <h2>Cadastre-se</h2>
        <p className="login-page-description">Preencha o formulário abaixo para criar uma conta.</p>
        <p className="login-page-description">Digite um número válido com DDD, pois ele será utilizado para recuperar sua senha.</p>
        <div className="input-container">
          <input
            type="text"
            placeholder="Nome Completo"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </div>
        <div className="input-container">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-container">
          <input
            type={mostrarSenha ? 'text' : 'password'}
            placeholder="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            className="password-input"
          />
          <span
            className="toggle-password"
            onClick={() => setMostrarSenha(!mostrarSenha)}
          >
            <i className={`fas ${mostrarSenha ? 'fa-eye-slash' : 'fa-eye'}`}></i>
          </span>
        </div>
        <div className="input-container">
          <input
            type={mostrarSenha ? 'text' : 'password'}
            placeholder="Confirme sua Senha"
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
            className="password-input"
          />
          <span
            className="toggle-password"
            onClick={() => setMostrarSenha(!mostrarSenha)}
          >
            <i className={`fas ${mostrarSenha ? 'fa-eye-slash' : 'fa-eye'}`}></i>
          </span>
        </div>
        <div className="input-container">
          <input
            type="tel"
            placeholder="Número do WhatsApp"
            value={numeroWhatsapp}
            onChange={(e) => setNumeroWhatsapp(e.target.value.replace(/\D/g, ''))} // Atualiza o número sem caracteres não numéricos
          />
        </div>
        {erro && <p className="error">{erro}</p>}
        <button onClick={handleCadastro}>Cadastrar</button>
        <button className="button-cadastrar" onClick={() => navigate('/')}>Voltar para a tela de login</button>
      </div>
    </div>
  );
};

export default Cadastro;
