import axios from 'axios';
import { startSync, stopSync } from './syncWords'; // Certifique-se de que os caminhos estejam corretos
import { getUserEmail } from './auth'; // Importa a função de autenticação para obter o email do usuário

const BASEROW_TOKEN = process.env.REACT_APP_BASEROW_USER_ONLINE_TOKEN;
const TABLE_ID = process.env.REACT_APP_BASEROW_USER_ONLINE_TABLE_ID;
const BASE_URL = 'https://api.baserow.io/api/database/rows/table/';

// Função para monitorar a atividade do usuário
export const startMonitoring = () => {
  document.addEventListener('mousemove', resetTimer, false);
  document.addEventListener('mousedown', resetTimer, false);
  document.addEventListener('keypress', resetTimer, false);
  document.addEventListener('touchmove', resetTimer, false);
  document.addEventListener('click', resetTimer, false);
  
  startSync(); // Inicia a verificação a cada 24 horas
};

// Função para interromper o monitoramento da atividade do usuário
export const stopMonitoring = () => {
  document.removeEventListener('mousemove', resetTimer, false);
  document.removeEventListener('mousedown', resetTimer, false);
  document.removeEventListener('keypress', resetTimer, false);
  document.removeEventListener('touchmove', resetTimer, false);
  document.removeEventListener('click', resetTimer, false);
  
  stopSync(); // Interrompe a verificação de 24 horas quando a monitorização para
};

// Função para excluir o usuário da tabela do Baserow após inatividade
const removerUsuarioInativo = async () => {
  const email = getUserEmail(); // Usa a função de autenticação para pegar o email do usuário
  
  try {
    // Primeiro, buscamos o ID da linha do usuário no Baserow, para então excluí-lo
    const response = await axios.get(`${BASE_URL}${TABLE_ID}/?user_field_names=true`, {
      headers: {
        Authorization: `Token ${BASEROW_TOKEN}`,
      },
    });

    // Encontra o usuário pelo email
    const usuario = response.data.results.find(user => user['E-mail'] === email);

    if (usuario) {
      // Se o usuário for encontrado, excluímos a linha dele
      await axios.delete(`${BASE_URL}${TABLE_ID}/${usuario.id}/`, {
        headers: {
          Authorization: `Token ${BASEROW_TOKEN}`,
        },
      });
      console.log('Usuário inativo excluído da tabela.');
    }
  } catch (error) {
    console.error('Erro ao excluir usuário inativo da tabela:', error);
  }
};

// Função para reiniciar o temporizador de inatividade
let timer;
const resetTimer = () => {
  clearTimeout(timer);
  timer = setTimeout(async () => {
    await removerUsuarioInativo(); // Exclui o usuário da tabela após 15 minutos de inatividade
    stopMonitoring(); // Interrompe o monitoramento
  }, 15 * 60 * 1000); // Interrompe o monitoramento após 15 minutos de inatividade
};
