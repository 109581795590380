import React from 'react';
import { Link } from 'react-router-dom';
import './PoliticaPublicidade.css';
import logo from '../imagens/logo_menu_login.webp'; // Importe a logo
import Rodape from '../componentes/Rodape'; // Importando o rodapé

const PoliticaPublicidade = () => {
  return (
    <div className="politica-page-unique">
      <header className="politica-header-unique">
        <img src={logo} alt="Logo da Empresa" className="politica-logo-unique" />
        <Link to="/login" className="politica-btn-login-unique">Voltar para o Login</Link>
      </header>
      <main className="politica-content-unique">
        <h1 className="politica-title-unique">Política de Privacidade</h1>
        <p className="politica-text-unique">
          No Transbordo AI, respeitamos a sua privacidade e estamos comprometidos em proteger as informações pessoais que você nos confia ao utilizar nossos serviços.
        </p>
        <h2 className="politica-subtitle-unique">1. Dados que Coletamos</h2>
        <p className="politica-text-unique">
          - **Dados de login**: Nome, e-mail e senha são coletados para permitir o acesso ao nosso sistema. Essas informações são armazenadas de forma segura e não são compartilhadas com terceiros.<br/>
          - **Tokens de Acesso Temporário**: Para integrações com serviços de terceiros, geramos tokens temporários para acesso aos dados necessários durante a sessão do usuário. Esses tokens são descartados ao fim da sessão e não armazenamos dados provenientes dessas integrações.
        </p>
        <h2 className="politica-subtitle-unique">2. Uso de Dados</h2>
        <p className="politica-text-unique">
          Os dados pessoais são utilizados exclusivamente para:<br/>
          - Gerenciar o acesso à plataforma.<br/>
          - Oferecer suporte aos usuários.<br/>
          - Gerar tokens temporários para uso durante as sessões de integração com serviços de terceiros, sem armazenar informações permanentes.
        </p>
        <h2 className="politica-subtitle-unique">3. Compartilhamento de Dados</h2>
        <p className="politica-text-unique">
          Não compartilhamos suas informações pessoais com terceiros, exceto nos casos necessários para cumprimento de obrigações legais ou com autorização do usuário.
        </p>
        <h2 className="politica-subtitle-unique">4. Segurança</h2>
        <p className="politica-text-unique">
          Implementamos medidas de segurança adequadas para proteger suas informações contra acessos não autorizados e usos indevidos.
        </p>
        <h2 className="politica-subtitle-unique">5. Contato</h2>
        <p className="politica-text-unique">
          Se você tiver dúvidas ou preocupações sobre esta política, entre em contato conosco através do e-mail: <a href="mailto:contato@ocaminhoparaprosperidade.com">contato@ocaminhoparaprosperidade.com</a>
        </p>

        <h1 className="politica-title-unique">Termos de Uso</h1>
        <h2 className="politica-subtitle-unique">1. Objetivo</h2>
        <p className="politica-text-unique">
          O Transbordo AI oferece ferramentas que auxiliam usuários em suas atividades digitais, incluindo criação de campanhas publicitárias, scripts de vendas, cálculos financeiros e análise de dados. Nossa plataforma facilita o uso de integrações com serviços de terceiros para melhorar a experiência do usuário, sem armazenar dados sensíveis permanentemente.
        </p>
        <h2 className="politica-subtitle-unique">2. Uso da Plataforma</h2>
        <p className="politica-text-unique">
          - Para utilizar a plataforma, o usuário deve criar uma conta com nome, e-mail e senha.<br/>
          - Integrações com serviços de terceiros são utilizadas para facilitar o acesso a dados e ferramentas durante a sessão do usuário. Nenhum dado obtido de tais serviços é armazenado permanentemente em nossos servidores.<br/>
          - O usuário é responsável por manter a confidencialidade de suas credenciais de login e por todas as atividades realizadas sob sua conta.
        </p>
        <h2 className="politica-subtitle-unique">3. Limitações de Responsabilidade</h2>
        <p className="politica-text-unique">
          O Transbordo AI não armazena permanentemente dados obtidos através de integrações com serviços de terceiros. Todos os dados são utilizados apenas durante a sessão ativa do usuário. Não nos responsabilizamos por falhas ou indisponibilidades nos serviços de terceiros integrados à nossa plataforma.
        </p>
        <h2 className="politica-subtitle-unique">4. Modificações</h2>
        <p className="politica-text-unique">
          Os Termos de Uso podem ser atualizados conforme necessário para garantir a conformidade com as exigências legais e melhorias na plataforma.
        </p>
      </main>

      <Rodape className="politica-footer-unique" />
    </div>
  );
}

export default PoliticaPublicidade;
