import axios from 'axios';
import { DateTime } from 'luxon';

const BASE_URL = 'https://api.baserow.io/api/database/rows/table/';
const API_TOKEN = process.env.REACT_APP_BASEROW_API_TOKEN_PALAVRA_CONTROLE;
const TABLE_ID = process.env.REACT_APP_BASEROW_TABLE_ID_PALAVRA_CONTROLE;

// Função para inicializar ou verificar palavras
export const inicializarPalavras = async (email) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
      {
        headers: {
          Authorization: `Token ${API_TOKEN}`,
        },
      }
    );

    const usuario = response.data.results.find(row => row.email === email);

    if (!usuario) {
      // Obter a data e hora atual do servidor e converter para horário de São Paulo
      const serverTime = DateTime.utc();
      const brazilTime = serverTime.setZone('America/Sao_Paulo').toISO();

      // Injetar palavras e data de renovação se o usuário não existir
      await axios.post(
        `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
        { 
          email, 
          palavras_disponiveis: 300000,
          data_renovacao_palavras: brazilTime
        },
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
          },
        }
      );
      return 300000; // Retornar o saldo inicial
    } else if (usuario.palavras_disponiveis === 0 || !usuario.palavras_disponiveis) {
      // Atualizar palavras se o saldo estiver zerado
      const serverTime = DateTime.utc();
      const brazilTime = serverTime.setZone('America/Sao_Paulo').toISO();

      await axios.patch(
        `${BASE_URL}${TABLE_ID}/${usuario.id}/?user_field_names=true`,
        { 
          palavras_disponiveis: 300000,
          data_renovacao_palavras: brazilTime
        },
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
          },
        }
      );
      return 300000; // Retornar o saldo atualizado
    } else {
      return usuario.palavras_disponiveis; // Retornar o saldo existente
    }
  } catch (error) {
    console.error('Erro ao inicializar/verificar palavras:', error.message);
    return null;
  }
};

// Função para consumir palavras
export const consumirPalavras = async (email, palavrasConsumidas) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
      {
        headers: {
          Authorization: `Token ${API_TOKEN}`,
        },
      }
    );

    const usuario = response.data.results.find(row => row.email === email);

    if (usuario) {
      const novasPalavras = usuario.palavras_disponiveis - palavrasConsumidas;

      if (novasPalavras < 0) {
        console.error('Erro: Limite de palavras excedido.');
        return false;
      }

      await axios.patch(
        `${BASE_URL}${TABLE_ID}/${usuario.id}/?user_field_names=true`,
        { palavras_disponiveis: novasPalavras },
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
          },
        }
      );

      return novasPalavras;
    } else {
      console.error('Usuário não encontrado.');
      return false;
    }
  } catch (error) {
    console.error('Erro ao consumir palavras:', error.message);
    return false;
  }
};

// Função para verificar saldo de palavras
export const verificarPalavras = async (email) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
      {
        headers: {
          Authorization: `Token ${API_TOKEN}`,
        },
      }
    );

    const usuario = response.data.results.find(row => row.email === email);
    return usuario ? usuario.palavras_disponiveis : 0;
  } catch (error) {
    console.error('Erro ao verificar palavras:', error.message);
    return null;
  }
};

// Função para atualizar saldo de palavras
export const atualizarSaldoPalavras = async (email, novoSaldo) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
      {
        headers: {
          Authorization: `Token ${API_TOKEN}`,
        },
      }
    );

    const usuario = response.data.results.find(row => row.email === email);

    if (usuario) {
      await axios.patch(
        `${BASE_URL}${TABLE_ID}/${usuario.id}/?user_field_names=true`,
        { palavras_disponiveis: novoSaldo },
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
          },
        }
      );

      return novoSaldo;
    } else {
      console.error('Usuário não encontrado.');
      return null;
    }
  } catch (error) {
    console.error('Erro ao atualizar saldo de palavras:', error.message);
    return null;
  }
};
