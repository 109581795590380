import React, { useState } from 'react';
import axios from 'axios';
import { FaKey } from 'react-icons/fa';
import './TrocaDeSenha.css'; // Usar o CSS personalizado

const TrocaDeSenha = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [erro, setErro] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [tokenEnviado, setTokenEnviado] = useState(false);
  const [tokenValido, setTokenValido] = useState(false);

  const handleEnviarToken = async () => {
    if (!email) {
      setErro('Por favor, insira um e-mail.');
      return;
    }

    try {
      // Usar exatamente os mesmos caminhos e credenciais
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
          },
        }
      );

      const usuarios = response.data.results;
      const usuarioExistente = usuarios.find(usuario => usuario['E-mail'] === email);

      if (!usuarioExistente) {
        setErro('E-mail não encontrado. Verifique e tente novamente.');
        return;
      }

      // Gerar um token de 6 dígitos
      const tokenGerado = Math.floor(100000 + Math.random() * 900000).toString();

      // Injetar o token no banco de dados primeiro
      await axios.patch(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/${usuarioExistente.id}/?user_field_names=true`,
        {
          'Token_para_validar_recuperacao_senha': tokenGerado,
        },
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
          },
        }
      );

      // Enviar o token para o webhook
      try {
        await axios.post(process.env.REACT_APP_RECUPERAR_SENHA_N8N_WEBHOOK_URL, {
          nome: usuarioExistente['Nome'],
          numeroWhatsapp: usuarioExistente['Numero do Whatsapp'], // Usar o número como está no banco de dados
          token: tokenGerado,
        });

        setMensagem('Token enviado para o seu WhatsApp.');
        setTokenEnviado(true);
      } catch (webhookError) {
        console.error('Erro ao enviar o token para o webhook:', webhookError);
        setErro('Ocorreu um erro ao enviar o token. Tente novamente mais tarde.');
      }
    } catch (error) {
      console.error('Erro ao verificar o e-mail no banco de dados:', error);
      setErro('Ocorreu um erro ao verificar o e-mail. Tente novamente mais tarde.');
    }
  };

  const handleValidarToken = async () => {
    try {
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
          },
        }
      );

      const usuarioExistente = response.data.results.find(usuario => usuario['E-mail'] === email);

      if (usuarioExistente && usuarioExistente['Token_para_validar_recuperacao_senha'] === token) {
        setTokenValido(true);
        setMensagem('Token validado com sucesso.');
      } else {
        setErro('Token inválido. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao validar o token:', error);
      setErro('Ocorreu um erro ao validar o token. Tente novamente mais tarde.');
    }
  };

  const handleRedefinirSenha = async () => {
    if (novaSenha !== confirmarNovaSenha) {
      setErro('As senhas não coincidem.');
      return;
    }

    try {
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
          },
        }
      );

      const usuarioExistente = response.data.results.find(usuario => usuario['E-mail'] === email);

      if (usuarioExistente) {
        await axios.patch(
          `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_RECUPERAR_SENHA}/${usuarioExistente.id}/?user_field_names=true`,
          {
            'senha': novaSenha,
            'Token_para_validar_recuperacao_senha': '',
          },
          {
            headers: {
              Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RECUPERAR_SENHA}`,
            },
          }
        );

        setMensagem('Senha atualizada com sucesso!');
        setTimeout(() => window.location.href = '/', 3000);
      } else {
        setErro('Erro ao atualizar a senha. Usuário não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao atualizar a senha:', error);
      setErro('Ocorreu um erro ao tentar atualizar a senha. Tente novamente mais tarde.');
    }
  };

  return (
    <div className="troca-senha-container-troca animated fadeIn">
      <div className="troca-senha-header-troca">
        <FaKey className="troca-senha-icon-troca" />
        <h2 className="troca-senha-titulo-troca">Trocar Senha</h2>
      </div>
      <p className="troca-senha-instrucao-troca">Insira o seu e-mail para receber o código de validação no seu WhatsApp.</p>

      {!tokenEnviado && (
        <>
          <div className="input-container-troca-troca">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-troca-troca"
            />
          </div>
          <button className="botao-troca-enviar" onClick={handleEnviarToken}>Enviar Token de Validação</button>
        </>
      )}

      {tokenEnviado && !tokenValido && (
        <>
          <div className="input-container-troca-troca">
            <input
              type="text"
              placeholder="Token de validação"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className="input-troca-troca"
            />
          </div>
          <button className="botao-troca-validar" onClick={handleValidarToken}>Validar Token</button>
        </>
      )}

      <div className="input-container-troca-troca">
        <input
          type={mostrarSenha ? 'text' : 'password'}
          placeholder="Nova Senha"
          value={novaSenha}
          onChange={(e) => setNovaSenha(e.target.value)}
          className="input-troca-troca"
          disabled={!tokenValido}
        />
      </div>
      <div className="input-container-troca-troca">
        <input
          type={mostrarSenha ? 'text' : 'password'}
          placeholder="Confirme sua Nova Senha"
          value={confirmarNovaSenha}
          onChange={(e) => setConfirmarNovaSenha(e.target.value)}
          className="input-troca-troca"
          disabled={!tokenValido}
        />
      </div>
      {tokenValido && (
        <button className="botao-troca-redefinir" onClick={handleRedefinirSenha}>Redefinir Senha</button>
      )}

      {erro && <p className="erro-troca-troca">{erro}</p>}
      {mensagem && <p className="mensagem-troca-troca">{mensagem}</p>}
    </div>
  );
};

export default TrocaDeSenha;
