import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaUser, FaEnvelope, FaPhone, FaSyncAlt, FaRegFileAlt } from 'react-icons/fa';
import './PainelDeControle.css';
import { isAuthenticated, getUserEmail } from '../../auth/auth';

const BASE_URL = 'https://api.baserow.io/api/database/rows/table/';
const UPLOAD_URL = 'https://api.baserow.io/api/user-files/upload-file/';
const API_TOKEN = process.env.REACT_APP_BASEROW_API_TOKEN_PALAVRAS_USUARIO;
const TABLE_ID = process.env.REACT_APP_BASEROW_TABLE_ID_PALAVRAS_USUARIO;

const PainelDeControle = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [palavrasDisponiveis, setPalavrasDisponiveis] = useState(0);
  const [erro, setErro] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(null);
  const [usuarioId, setUsuarioId] = useState(null);

  const fetchUserData = async (userEmail) => {
    try {
      // Verificar no localStorage
      const storedFotoUrl = localStorage.getItem(`${userEmail}-fotoPerfil`);
      if (storedFotoUrl) {
        setFotoPerfil(storedFotoUrl);
      }

      const response = await axios.get(`${BASE_URL}${TABLE_ID}/?user_field_names=true`, {
        headers: {
          Authorization: `Token ${API_TOKEN}`,
        },
      });

      const usuario = response.data.results.find(row => row['email'] === userEmail);
      console.log('Dados do Usuário:', usuario);

      if (usuario) {
        setNome(usuario['Nome'] || '');
        setTelefone(usuario['Numero de Telefone'] || 'Não disponível');
        setUsuarioId(usuario.id);

        const palavrasRestantes = usuario['palavras_disponiveis'];
        setPalavrasDisponiveis(palavrasRestantes);

        localStorage.setItem(`${userEmail}-nomeUsuario`, usuario['Nome'] || '');
        localStorage.setItem(`${userEmail}-telefoneUsuario`, usuario['Numero de Telefone'] || '');
        localStorage.setItem(`${userEmail}-palavrasDisponiveis`, palavrasRestantes);

        if (usuario['Foto de Perfil'] && usuario['Foto de Perfil'].length > 0) {
          const fotoUrl = usuario['Foto de Perfil'][0].url;
          console.log('URL da Foto de Perfil:', fotoUrl);
          setFotoPerfil(fotoUrl);
          // Salvar no localStorage
          localStorage.setItem(`${userEmail}-fotoPerfil`, fotoUrl);
        } else {
          setFotoPerfil(null);
          localStorage.removeItem(`${userEmail}-fotoPerfil`);
        }
      } else {
        setErro('Usuário não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);
      setErro('Erro ao buscar dados do usuário.');
    }
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      setErro('Usuário não autenticado.');
      return;
    }

    const userEmail = getUserEmail();
    setEmail(userEmail);

    fetchUserData(userEmail);
    const interval = setInterval(() => fetchUserData(userEmail), 1800000); // Atualiza a cada 30 minutos

    return () => clearInterval(interval);
  }, []);

  const formatarNumero = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/png'];

      if (!allowedTypes.includes(file.type)) {
        alert('Por favor, selecione uma imagem nos formatos PNG ou JPG.');
        return;
      }

      await uploadImage(file);
    }
  };

  const uploadImage = async (file) => {
    if (!file || !usuarioId) {
      alert('Nenhuma imagem selecionada ou usuário inválido.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      console.log('Iniciando upload do arquivo...');
      const uploadResponse = await axios.post(
        UPLOAD_URL,
        formData,
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
          },
        }
      );

      console.log('Resposta do Upload:', uploadResponse.data);

      if (!uploadResponse.data) {
        throw new Error('Resposta de upload inválida.');
      }

      // Usar o nome do arquivo ou outra propriedade única como identificador
      const fileIdentifier = uploadResponse.data.name || uploadResponse.data.original_name;

      const patchResponse = await axios.patch(
        `${BASE_URL}${TABLE_ID}/${usuarioId}/?user_field_names=true`,
        {
          'Foto de Perfil': [fileIdentifier],
        },
        {
          headers: {
            Authorization: `Token ${API_TOKEN}`,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Resposta do PATCH:', patchResponse.data);

      if (patchResponse.data && patchResponse.data['Foto de Perfil'] && patchResponse.data['Foto de Perfil'].length > 0) {
        const newFotoUrl = patchResponse.data['Foto de Perfil'][0].url;
        setFotoPerfil(newFotoUrl);
        // Salvar no localStorage após o upload
        localStorage.setItem(`${email}-fotoPerfil`, newFotoUrl);
        alert('Foto de perfil atualizada com sucesso!');
      } else {
        throw new Error('Não foi possível obter a URL da nova foto de perfil.');
      }

      // Atualiza os dados do usuário após o upload bem-sucedido
      await fetchUserData(email);
    } catch (error) {
      console.error('Erro ao fazer upload da imagem:', error);
      if (error.response) {
        console.error('Detalhes do erro:', error.response.data);
        alert(`Erro ao atualizar a foto de perfil: ${error.response.data.error || 'Erro desconhecido.'}`);
      } else {
        alert('Erro ao atualizar a foto de perfil: ' + error.message);
      }
    }
  };

  if (erro) {
    return <div className="erro-painel">{erro}</div>;
  }

  const palavrasUsadas = 50000 - palavrasDisponiveis;

  return (
    <div className="painel-de-controle-container-painel">
      <div className="painel-assinatura-painel">
        <div className="painel-icone-titulo-painel">
          <FaSyncAlt className="icone-assinatura-painel" />
          <h2 className="titulo-assinatura-painel">Assinatura</h2>
        </div>
        <p className="descricao-assinatura-painel">Gerencie sua assinatura e veja as palavras restantes e usadas.</p>
        <div className="painel-barra-container-painel">
          <div className="painel-barra-painel">
            <div
              className="painel-barra-progresso-painel"
              style={{ width: `${(palavrasDisponiveis / 50000) * 100}%`, backgroundColor: '#FFD700' }}
            />
          </div>
          <div className="painel-barra-texto-painel">
            <FaRegFileAlt className="icone-palavras-painel" />
            {formatarNumero(palavrasDisponiveis)} palavras restantes
          </div>
        </div>
        <button className="painel-botao-upgrade-painel">Faça um upgrade no seu plano</button>
      </div>

      <div className="painel-visao-geral-painel">
        <h2 className="titulo-visao-geral-painel">Visão Geral</h2>
        <p className="descricao-visao-geral-painel">Entenda e gerencie suas informações pessoais.</p>

        <div className="foto-perfil-section">
          {fotoPerfil ? (
            <img src={fotoPerfil} alt="Foto de Perfil" className="foto-perfill" />
          ) : (
            <div className="foto-perfil-placeholder">Sem Foto</div>
          )}
          <button
            className="botao-alterar-foto"
            onClick={() => document.getElementById('fileInput').click()}
          >
            Alterar Foto
          </button>
          <input
            type="file"
            id="fileInput"
            accept="image/png, image/jpeg"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </div>

        <div className="visao-geral-info-painel">
          <div className="linha-info-painel">
            <FaUser className="icone-visao-geral-painel" />
            <span>{nome}</span>
          </div>
          <div className="linha-info-painel">
            <FaEnvelope className="icone-visao-geral-painel" />
            <span>{email}</span>
          </div>
          <div className="linha-info-painel">
            <FaPhone className="icone-visao-geral-painel" />
            <span>{telefone}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PainelDeControle;