import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getUserEmail } from '../../auth/auth';  // Caminho corrigido para o arquivo de autenticação
import { FaUser, FaEnvelope, FaCheckCircle } from 'react-icons/fa'; // Importação dos ícones
import './PalavrasUsuario.css'; // Importação do CSS original

const BASE_URL = 'https://api.baserow.io/api/database/rows/table/';
const API_TOKEN = process.env.REACT_APP_BASEROW_API_TOKEN_PALAVRAS_USUARIO;
const TABLE_ID = process.env.REACT_APP_BASEROW_TABLE_ID_PALAVRAS_USUARIO;

const PalavrasUsuario = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState(getUserEmail());
  const [palavrasDisponiveis, setPalavrasDisponiveis] = useState(0);
  const [erro, setErro] = useState(null);
  const [fotoPerfil, setFotoPerfil] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!email) {
        setErro('Usuário não autenticado.');
        return;
      }

      const nomeLocalStorage = localStorage.getItem(`${email}-nomeUsuario`);
      const palavrasLocalStorage = localStorage.getItem(`${email}-palavrasDisponiveis`);
      const fotoPerfilLocalStorage = localStorage.getItem(`${email}-fotoPerfil`);

      if (nomeLocalStorage && palavrasLocalStorage && fotoPerfilLocalStorage !== null) {
        setNome(nomeLocalStorage);
        setPalavrasDisponiveis(Number(palavrasLocalStorage));
        setFotoPerfil(fotoPerfilLocalStorage);
        return; // Se já existe no localStorage, não precisa buscar novamente
      }

      try {
        const response = await axios.get(
          `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
          {
            headers: {
              Authorization: `Token ${API_TOKEN}`,
            },
          }
        );

        const usuario = response.data.results.find(row => row.email === email);

        if (usuario) {
          setNome(usuario.Nome);
          setPalavrasDisponiveis(usuario.palavras_disponiveis);
          localStorage.setItem(`${email}-nomeUsuario`, usuario.Nome);
          localStorage.setItem(`${email}-palavrasDisponiveis`, usuario.palavras_disponiveis);

          // Verifica se há uma foto de perfil
          if (usuario['Foto de Perfil'] && usuario['Foto de Perfil'].length > 0) {
            const fotoUrl = usuario['Foto de Perfil'][0].url;
            setFotoPerfil(fotoUrl);
            localStorage.setItem(`${email}-fotoPerfil`, fotoUrl);
          } else {
            setFotoPerfil(null);
            localStorage.setItem(`${email}-fotoPerfil`, '');
          }
        } else {
          setErro('Usuário não encontrado.');
        }
      } catch (error) {
        setErro('Erro ao buscar dados do usuário.');
      }
    };

    fetchUserData();
    const interval = setInterval(fetchUserData, 1800000); // Requisição a cada 30 minutos
    return () => clearInterval(interval); // Limpa o intervalo quando o componente é desmontado
  }, [email]);

  const formatarNumero = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  if (erro) {
    return <div className="erro">{erro}</div>;
  }

  return (
    <div className="usuario-info">
      <div className="linha">
        {fotoPerfil ? (
          <img src={fotoPerfil} alt="Foto de Perfil" className="foto-perfil-vertical" />
        ) : (
          <FaUser className="foto-perfil" />
        )}
        <div className="nome">{nome}</div>
      </div>
      <div className="linha">
        <FaEnvelope className="icone-usuario" />
        <div className="email">{email}</div>
      </div>
      <div className="barra-palavras">
        <div
          className="progresso"
          style={{ width: `${(palavrasDisponiveis / 50000) * 100}%` }}
        ></div>
      </div>
      <div className="quantidade-palavras">
        <FaCheckCircle className="icone" />
        {formatarNumero(palavrasDisponiveis)} palavras restantes
      </div>
    </div>
  );
};

export default PalavrasUsuario;