import React, { useState } from 'react';
import {
  DollarSign, TrendingUp, MousePointer, Target, ShoppingCart, Calendar,
  Briefcase, MessageCircle, Mail, PieChart, BarChart2, CreditCard,
  ShoppingBag, Zap, XCircle, Frown, Award, Clock
} from 'lucide-react';
import './Metricas.css';

const metrics = {
  facebook: [
    { title: "CPM médio", description: "Custo por mil impressões. Exemplo: Se você gastar R$10 para alcançar 1000 pessoas, seu CPM é R$10.", icon: DollarSign },
    { title: "CPC ideal", description: "Custo por clique. Exemplo: Se você gastar R$10 e receber 100 cliques, seu CPC é R$0.10.", icon: MousePointer },
    { title: "CPA ideal", description: "Custo por aquisição. Exemplo: Se você gastar R$100 e fizer 10 vendas, seu CPA é R$10.", icon: ShoppingCart },
  ],
  whatsapp: [
    { title: "Mensagens geradas por dia", description: "Número de mensagens que você deve enviar por dia. Exemplo: 100 mensagens por dia para gerar 10 vendas.", icon: MessageCircle },
    { title: "Custo por mensagem", description: "Valor que você gasta por mensagem enviada. Exemplo: Se você gastar R$50 para enviar 100 mensagens, o custo por mensagem é R$0.50.", icon: Mail },
    { title: "Taxa de conversão do WhatsApp", description: "Taxa de conversão das mensagens enviadas. Exemplo: Se 10% das mensagens geram vendas, a taxa de conversão é 10%.", icon: PieChart },
  ],
  finance: [
    { title: "Faturamento no período", description: "Valor total faturado no período. Exemplo: Se você fizer R$1000 em vendas em 30 dias, esse é seu faturamento.", icon: CreditCard },
    { title: "Valor total de taxas", description: "Valor total pago em taxas. Exemplo: Se você paga 5% de taxa em R$1000, o valor total de taxas é R$50.", icon: DollarSign },
    { title: "Pedidos frustrados estimados", description: "Número de pedidos que podem ser frustrados. Exemplo: Se você estima que 5% das vendas não serão concluídas, esse é o número de pedidos frustrados.", icon: XCircle },
    { title: "Custo total de frustração", description: "Valor total perdido devido a pedidos frustrados. Exemplo: Se cada pedido frustrado custa R$5 e você tem 10 pedidos frustrados, o custo total é R$50.", icon: Frown },
    { title: "Período necessário para alcançar o lucro", description: "Tempo necessário para alcançar o lucro desejado. Exemplo: Se você precisa de 20 dias para atingir R$1000 de lucro, esse é o período necessário.", icon: Clock },
  ]
};

const MetricCard = ({ title, description, icon: Icon }) => (
  <div className="metric-card">
    <div className="metric-header">
      <Icon className="metric-icon" size={24} />
      <h3 className="metric-title">{title}</h3>
    </div>
    <div className="metric-description">
      <p>{description}</p>
    </div>
  </div>
);

const Metricas = () => {
  return (
    <div className="metricas-container">
      <h2 className="metricas-title">📘 O que significa cada métrica?</h2>
      <div className="metricas-category">
        <h3 className="category-title">Métricas do Facebook</h3>
        <div className="metricas-grid">
          {metrics.facebook.map((metric, index) => (
            <MetricCard
              key={index}
              title={metric.title}
              description={metric.description}
              icon={metric.icon}
            />
          ))}
        </div>
      </div>
      <div className="metricas-category">
        <h3 className="category-title">Métricas de Conversão para WhatsApp</h3>
        <div className="metricas-grid">
          {metrics.whatsapp.map((metric, index) => (
            <MetricCard
              key={index}
              title={metric.title}
              description={metric.description}
              icon={metric.icon}
            />
          ))}
        </div>
      </div>
      <div className="metricas-category">
        <h3 className="category-title">Resultados Financeiros</h3>
        <div className="metricas-grid">
          {metrics.finance.map((metric, index) => (
            <MetricCard
              key={index}
              title={metric.title}
              description={metric.description}
              icon={metric.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Metricas;
