import React, { useState } from 'react';
import { Sparkles, ChevronDown, ChevronUp } from 'lucide-react';
import './Oportunidades.css';

const MarketOpportunity = ({ name, icon, description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div 
      className={`opportunity-card ${isExpanded ? 'expanded' : ''}`}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="opportunity-header">
        <div className="opportunity-icon-name">
          <span className="opportunity-icon">{icon}</span>
          <h3 className="opportunity-name">{name}</h3>
        </div>
        {isExpanded ? <ChevronUp className="chevron-icon" /> : <ChevronDown className="chevron-icon" />}
      </div>
      <p className={`opportunity-description ${isExpanded ? 'show' : 'hide'}`}>
        {description}
      </p>
    </div>
  );
};

const Oportunidades = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const opportunities = [
    {
      name: "LOGÍSTICA INTEGRADA (LOGZZ)",
      icon: "🚚",
      description: "A Logzz é a primeira e única plataforma segura de Cash on Delivery® do Brasil. Oferece um sistema de afiliação para produtos com entrega em até 24 horas, frete grátis e pagamento apenas na entrega. Ideal para empreendedores digitais que buscam uma solução logística confiável e inovadora."
    },
    {
      name: "MERCADO DE ENCAPSULADOS",
      icon: "💊",
      description: "Explore o crescente mercado de saúde online com a produção e venda de suplementos e vitaminas encapsuladas. Atenda à demanda por produtos de bem-estar personalizados."
    },
    {
      name: "AFILIADO DE INFOPRODUTO",
      icon: "🔗",
      description: "Torne-se um especialista em marketing de afiliados, promovendo produtos digitais de alta qualidade. Ganhe comissões atrativas e construa uma fonte de renda passiva."
    },
    {
      name: "DROPSHIPPING",
      icon: "📦",
      description: "Inicie seu e-commerce sem estoque! Venda produtos online e deixe o fornecedor cuidar do envio. Minimize riscos e maximize lucros com este modelo de negócio flexível."
    },
    {
      name: "CANAL NO YOUTUBE",
      icon: "🎥",
      description: "Transforme sua paixão em lucro! Crie conteúdo envolvente em vídeo e monetize através de anúncios, patrocínios e venda de produtos relacionados ao seu nicho."
    },
    {
      name: "LANÇAMENTO DE PRODUTO DIGITAL / COPRODUÇÃO",
      icon: "🚀",
      description: "Liberte sua criatividade! Desenvolva e lance seus próprios produtos digitais ou faça parcerias estratégicas para produzir e vender conteúdo exclusivo."
    },
    {
      name: "GESTOR DE TRÁFEGO PAGO",
      icon: "📊",
      description: "Especialize-se em gerir campanhas de anúncios pagos, como Google Ads e Facebook Ads. Auxilie empresas a alcançarem seu público-alvo e aumentarem suas vendas, ganhando comissões ou taxas de serviço."
    },
    {
      name: "AUTOMAÇÃO COM CHATBOT",
      icon: "🤖",
      description: "Implemente soluções de chatbot para empresas, automatizando atendimento ao cliente e processos de vendas. Economize tempo e recursos para os negócios, ao mesmo tempo em que cria uma nova fonte de receita."
    },
    {
      name: "AUTOMAÇÃO COM INTELIGÊNCIA ARTIFICIAL",
      icon: "💡",
      description: "Desenvolva sistemas de automação utilizando inteligência artificial para otimizar processos empresariais, desde atendimento ao cliente até análises de dados. Este campo está em crescimento e oferece vastas oportunidades de inovação e lucro."
    }
  ];

  return (
    <div className="oportunidades-container">
      <h1 className="oportunidades-title">
        <Sparkles className="inline mr-2" />
        Oportunidades de Negócios Digitais
        <Sparkles className="inline ml-2" />
      </h1>
      <div className="oportunidades-grid">
        {opportunities.map((opportunity, index) => (
          <div 
            key={index}
            className={`opportunity-wrapper ${hoveredIndex === index ? 'hovered' : ''}`}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <MarketOpportunity {...opportunity} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Oportunidades;
