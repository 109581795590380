import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdminAuthenticated, adminLogout } from '../../auth/auth';
import logo from '../../imagens/logo_menu_login.webp'; // Caminho correto da logo
import './adminUsuario.css'; // Novo arquivo CSS adaptado
import UsuariosOnline from './UsuariosOnline'; // Importando o novo componente

const AdminUsuario = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o administrador está autenticado
    if (!isAdminAuthenticated()) {
      navigate('/adminLogin');
    }
  }, [navigate]);

  const handleLogout = () => {
    adminLogout(); // Função para deslogar o administrador
  };

  const handleBackToMenu = () => {
    navigate('/painelAdmin'); // Navega de volta para o menu da administração
  };

  return (
    <div className="admin-usuario-container">
      <div className="admin-usuario-header">
        <img src={logo} alt="Logo" className="admin-usuario-logo" />
        <div className="admin-usuario-user-info">
          <span>Bem-vindo(a), Admin</span>
          <button className="admin-usuario-button" onClick={handleLogout}>Sair</button>
        </div>
      </div>
      <div className="admin-usuario-content">
        <h1 className="admin-usuario-title-gradient">Gerenciamento de Usuários</h1>
        <p className="admin-usuario-description">
          Aqui você pode gerenciar todos os usuários do sistema.
        </p>
         <button className="admin-voltar-menu-button" onClick={handleBackToMenu}>
          Voltar para o Menu
        </button>

        {/* Renderizando a lista de usuários online */}
        <UsuariosOnline />

        {/* Botão para voltar ao menu de administração */}
       
      </div>
    </div>
  );
};

export default AdminUsuario;
