import React, { useState, useEffect } from 'react';
import { Monitor, ShoppingCart, Target, MessageSquare, Users, Headphones, DollarSign, Brain } from 'lucide-react';
import './SalaryMap.css';

const steps = [
  { id: 1, text: "DESENVOLVER HABILIDADE", icon: Brain },
  { id: 2, text: "SE CADASTRAR EM ALGUMA PLATAFORMA (LOGZZ)(HOTMART)", icon: Monitor },
  { id: 3, text: "QUAL TIPO DE PRODUTO QUERO VENDER (FÍSICO OU INFOPRODUTO)", icon: ShoppingCart },
  { id: 4, text: "DEFINIR NICHO SAÚDE, MODA, EDUCAÇÃO...", icon: Target },
  { id: 5, text: "CRIAR ANÚNCIO EM ALGUMA FONTE DE TRÁFEGO (FACEBOOK)", icon: MessageSquare },
  { id: 6, text: "CAPTAÇÃO DE POTENCIAL CLIENTE PARA WHATSAPP", icon: Users },
  { id: 7, text: "ATENDIMENTO DO SEU POTENCIAL CLIENTE NO WHATSAPP", icon: Headphones },
  { id: 8, text: "VENDA CONCLUÍDA", icon: DollarSign },
];

const SalaryMap = () => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % steps.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="salary-map-container">
      <h1 className="salary-map-title">MAPA PARA FAZER SALÁRIOS NA INTERNET</h1>
      <div className="steps-container">
        <div className="line" />
        <div className="steps">
          {steps.map((step, index) => (
            <div key={step.id} className="step">
              <div className={`icon-container ${index === activeStep ? 'active' : ''}`}>
                {React.createElement(step.icon, { size: 36 })}
              </div>
              <div className={`step-description ${index % 2 === 0 ? 'above' : 'below'}`}>
                <p className={`step-text ${index === activeStep ? 'active' : ''}`}>
                  {`0${step.id}: ${step.text}`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="project-note">PROJETO MEU PRIMEIRO SALÁRIO SEM SAIR DE CASA</div>
    </div>
  );
};

export default SalaryMap;
