// src/components/AnaliseMetricas.js

import React, { useEffect, useState } from 'react';
import logo from '../../imagens/logo_menu_login.webp';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import './AnaliseMetricas.css';
import { FaArrowLeft } from 'react-icons/fa';
import FacebookLogin from './FacebookLogin'; // Importando o componente de login do Facebook
import FacebookAdsDashboard from './FacebookAdsDashboard'; // Importando o Dashboard de Anúncios do Facebook
import Chat from './Chat'; // Importando o componente de Chat

const AnaliseMetricas = () => {
  const navigate = useNavigate();
  const [facebookToken, setFacebookToken] = useState(null); // Estado para armazenar o token do Facebook
  const [metricsData, setMetricsData] = useState(null); // Estado compartilhado para as métricas

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  const userEmail = localStorage.getItem('userEmail');

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleBackToMenu = () => {
    navigate('/menu');
  };

  // Função chamada ao fazer login no Facebook com sucesso
  const handleFacebookLoginSuccess = (token) => {
    setFacebookToken(token); // Armazena o token do Facebook
  };

  return (
    <div className="analise-metricas-container">
      <div className="analise-metricas-header">
        <img src={logo} alt="Logo" className="analise-metricas-logo" />
        <div className="analise-metricas-user-info">
          <span>{userEmail}</span>
          <button className="analise-metricas-button" onClick={handleLogout}>Sair</button>
        </div>
      </div>
      <div className="analise-metricas-content">
        <h1 className="analise-metricas-title-gradient">Análise de Métricas</h1>
        <p className="analise-metricas-description">Um guia essencial para entender e dominar as métricas do Facebook Ads.</p>
        <button className="analise-metricas-button" onClick={handleBackToMenu}><FaArrowLeft /> Voltar para Menu</button>

        {/* Botão de login do Facebook */}
        <FacebookLogin onLoginSuccess={handleFacebookLoginSuccess} />

        {/* Renderiza as métricas do Facebook se o token estiver presente */}
        {facebookToken && <FacebookAdsDashboard setMetricsData={setMetricsData} />}
      </div>

      {/* Renderiza o Chat após as métricas do Facebook */}
      <Chat metricsData={metricsData} />
    </div>
  );
};

export default AnaliseMetricas;
