import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import logo from '../imagens/logo_menu_login.webp';
import { login } from '../auth/auth';
import Rodape from '../componentes/Rodape'; // Importando o rodapé
import { Helmet } from 'react-helmet'; // Importando Helmet para manipular o head

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
        `https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_BASEROW_TABLE_ID_LOGIN}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_LOGIN}`,
          },
        }
      );

      const usuarios = response.data.results;
      const usuario = usuarios.find(
        (usuario) => usuario['E-mail'] === email && usuario.senha === senha
      );

      if (usuario) {
        console.log('Login bem-sucedido, redirecionando para o menu...');
        login('fakeToken', email, usuario.Nome); // Passa o nome do usuário para a função de login
        navigate('/menu');
      } else {
        setErro('Email ou senha incorretos.');
      }
    } catch (error) {
      console.error('Erro ao verificar login:', error);
      setErro('Ocorreu um erro ao tentar fazer login. Tente novamente mais tarde.');
    }
  };

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById('password');
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
    } else {
      passwordInput.type = 'password';
    }
  };

  return (
    <div className="login-container">
      {/* Helmet para adicionar meta tags ao head */}
      <Helmet>
        <meta name="facebook-domain-verification" content="saa0d3f2706qu8rbd6xw0yd484hjjm" />
      </Helmet>

      <div className="logo-container">
        <img src={logo} alt="Logo" className="login-logo" />
      </div>
      <div className="form-container">
        <h1 className="gradient-text">O Caminho para a Prosperidade Digital </h1>
        <p className="gradient-text">Sua jornada para o sucesso começa aqui!</p>
        <h2 className="login-title">Bem-vindo!</h2>
        <p className="login-page-description">Aqui você encontra as ferramentas essenciais para iniciar sua jornada digital e alcançar a prosperidade.</p>
        <form onSubmit={handleLogin}>
          <div className="input-container">
            <i className="fas fa-envelope"></i>
            <input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              id="password"
              placeholder="Senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
              className="password-input"
            />
            <i className="fas fa-eye toggle-password" onClick={togglePasswordVisibility}></i>
          </div>
          {erro && <p className="error">{erro}</p>}
          <button type="submit">Entrar</button>
        </form>
        <div className="button-group">
          <button onClick={() => navigate('/cadastro')}>Cadastre-se</button>
          <button onClick={() => navigate('/recuperar-senha')}>Recuperar Senha</button>
        </div>
        <a href="https://www.youtube.com/channel/UClNvU7YxN8zt-Nj4GpSljSA" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <button id="youtube-button">
            <i className="fab fa-youtube"></i> Acesse nosso canal no YouTube
          </button>
        </a>
        <p className="gradient-text">O caminho para prosperidade</p>
      </div>

      {/* Rodapé renderizado fora do bloco principal */}
      <Rodape />
    </div>
  );
};

export default Login;
