import React, { useEffect } from 'react';
import './EstudioCriacao.css';
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';

const EstudioCriacao = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="estudio-criacao-container">
      <div className="estudio-criacao-header">
        <img src={logo} alt="Logo" className="estudio-criacao-logo" />
        <div className="estudio-criacao-user-info">
          <span>{userEmail}</span>
          <button className="estudio-criacao-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="estudio-criacao-content">
        <h1 className="estudio-criacao-title-gradient">Estúdio de Criação</h1>
        <p className="estudio-criacao-description">
          Explore uma variedade de ferramentas que facilitam a criação de conteúdo digital, desde edição de vídeos até clonagem de voz. Acesse o nosso arsenal de ferramentas atualizado regularmente, organizado por categorias para facilitar sua escolha.
        </p>
        <a href="https://pattern-babcat-82e.notion.site/Links-das-Ferramentas-09bc8a2e6ae246b4a6539b44bc3cd87b?pvs=4" className="estudio-criacao-button-large" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faExternalLinkAlt} /> Acesse nosso arsenal de ferramentas
        </a>
        <Link to="/menu" className="estudio-criacao-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>
      </div>
    </div>
  );
};

export default EstudioCriacao;
