import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUser, FaClock } from 'react-icons/fa'; // Ícones de usuário e relógio
import { format } from 'date-fns'; // Biblioteca para formatação de datas
import './UsuariosOnline.css'; // Importando o CSS fornecido

const UsuariosOnline = () => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const BASE_URL = 'https://api.baserow.io/api/database/rows/table/';
  const TABLE_ID = process.env.REACT_APP_BASEROW_USER_ONLINE_TABLE_ID;
  const BASEROW_TOKEN = process.env.REACT_APP_BASEROW_USER_ONLINE_TOKEN;

  // Função para formatar a data e hora no formato desejado (dia/mês/ano e hora:minuto)
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm'); // Formato brasileiro: 31/12/2023 23:59
  };

  // Função para buscar os usuários online no Baserow
  const fetchOnlineUsers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${TABLE_ID}/?user_field_names=true`, {
        headers: {
          Authorization: `Token ${BASEROW_TOKEN}`,
        },
      });

      // Filtra os usuários que estão com a atividade marcada como 'online'
      const onlineUsersData = response.data.results.filter(user => user.Atividade === true);
      setOnlineUsers(onlineUsersData);
    } catch (error) {
      console.error('Erro ao buscar usuários online no Baserow', error);
    }
  };

  // Função para remover um usuário da tabela
  const removeUser = async (userId) => {
    try {
      await axios.delete(`${BASE_URL}${TABLE_ID}/${userId}/`, {
        headers: {
          Authorization: `Token ${BASEROW_TOKEN}`,
        },
      });
      // Atualiza a lista de usuários online após a remoção
      fetchOnlineUsers();
    } catch (error) {
      console.error('Erro ao remover o usuário', error);
    }
  };

  useEffect(() => {
    fetchOnlineUsers(); // Chama a função ao carregar a página
  }, []);

  return (
    <div className="usuarios-online-container-unique">
      <h2 className="titulo-usuarios-online-unique">Usuários Online</h2>
      <ul className={`usuarios-online-list-unique ${onlineUsers.length >= 10 ? 'scroll-active-unique' : ''}`}>
        {onlineUsers.length > 0 ? (
          onlineUsers.map((user, index) => (
            <li key={index} className="usuario-online-item-unique">
              <div className="user-icon-unique">
                <FaUser />
              </div>
              <div className="user-name-unique">{user.Nome}</div>
              <div className="user-email-unique">{user['E-mail']}</div>
              <div className="clock-icon-unique">
                <FaClock />
              </div>
              <div className="user-last-activity-unique">
                {formatDate(user['Última atividade'])} {/* Exibe a data formatada */}
              </div>
              <button
                className="remove-user-button"
                onClick={() => removeUser(user.id)}
              >
                Remover
              </button>
            </li>
          ))
        ) : (
          <li className="no-users-unique">Nenhum usuário online no momento</li>
        )}
      </ul>
    </div>
  );
};

export default UsuariosOnline;
