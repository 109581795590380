import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faEnvelope, faCommentDots, faHeadset } from '@fortawesome/free-solid-svg-icons';
import './ChatSuporte.css'; // Use o CSS específico para ChatSuporte

const ChatSuporte = () => {
    const [categoria, setCategoria] = useState('');
    const [assunto, setAssunto] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [erro, setErro] = useState('');
    const [sucesso, setSucesso] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErro('');
        setSucesso('');

        const userEmail = localStorage.getItem('userEmail');
        const token = process.env.REACT_APP_BASEROW_API_TOKEN_SUPORTE;
        const ticketsTableId = process.env.REACT_APP_BASEROW_TABLE_ID_TICKETS;
        const interacoesTableId = process.env.REACT_APP_BASEROW_TABLE_ID_INTERACOES;
        const webhookUrl = process.env.REACT_APP_WEBHOOK_SUPORTE_URL;

        if (!userEmail || !categoria || !assunto || !mensagem) {
            setErro('Por favor, preencha todos os campos.');
            return;
        }

        try {
            // Verificar se há um ticket em aberto para este usuário e categoria
            const ticketResponse = await axios.get(
                `https://api.baserow.io/api/database/rows/table/${ticketsTableId}/?user_field_names=true&filter__field_3=${userEmail}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );

            const tickets = ticketResponse.data.results;
            let ticketAtual = tickets.find(ticket => 
                ticket.Status === 'Em Aberto' && 
                ticket['E-mail do Usuário'] === userEmail && 
                ticket.Categoria === categoria
            );

            // Se não houver ticket em aberto para este usuário e categoria, criar um novo
            if (!ticketAtual) {
                const newTicketResponse = await axios.post(
                    `https://api.baserow.io/api/database/rows/table/${ticketsTableId}/?user_field_names=true`,
                    {
                        'E-mail do Usuário': userEmail,
                        'Assunto': assunto,
                        'Categoria': categoria,
                        'Status': 'Em Aberto',
                        'Data de Criação': new Date().toISOString(),
                        'Última Atualização': new Date().toISOString(),
                    },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );

                ticketAtual = newTicketResponse.data;
            }

            // Inserir na tabela de Interações associada ao ticket atual
            await axios.post(
                `https://api.baserow.io/api/database/rows/table/${interacoesTableId}/?user_field_names=true`,
                {
                    'ID do Ticket': ticketAtual.id,
                    'E-mail do Usuário': userEmail,
                    'Tipo de Interação': 'Usuário',
                    'Conteúdo da Mensagem': mensagem,
                    'Data de Envio': new Date().toISOString(),
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );

            // Enviar Webhook para notificação
            await axios.post(webhookUrl, {
                email: userEmail,
                assunto: assunto,
                mensagem: mensagem,
                categoria: categoria,
            });

            setSucesso('Sua mensagem foi enviada com sucesso! Em breve nossa equipe entrará em contato.');
            setCategoria('');
            setAssunto('');
            setMensagem('');
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
            setErro('Ocorreu um erro ao enviar sua mensagem. Tente novamente mais tarde.');
        }
    };

    return (
        <div className="chat-suporte-container">
            <form onSubmit={handleSubmit} className="chat-suporte-form">
                <h2>
                    <FontAwesomeIcon icon={faHeadset} className="icon" /> Suporte ao Cliente
                </h2>
                <div className="chat-suporte-input-group">
                    <label htmlFor="categoria">
                        <FontAwesomeIcon icon={faCommentDots} className="icon" /> Categoria
                    </label>
                    <div className="input-with-icon">
                        <select
                            id="categoria"
                            value={categoria}
                            onChange={(e) => setCategoria(e.target.value)}
                        >
                            <option value="">Selecione uma categoria</option>
                            <option value="Problemas Técnicos">Problemas Técnicos</option>
                            <option value="Ideias de Melhorias">Ideias de Melhorias</option>
                            <option value="Outros">Outros</option>
                        </select>
                        <FontAwesomeIcon icon={faChevronDown} className="icon-dropdown" />
                    </div>
                </div>

                <div className="chat-suporte-input-group">
                    <label htmlFor="assunto">
                        <FontAwesomeIcon icon={faEnvelope} className="icon" /> Assunto
                    </label>
                    <div className="input-with-icon">
                        <input
                            id="assunto"
                            type="text"
                            value={assunto}
                            onChange={(e) => setAssunto(e.target.value)}
                        />
                    </div>
                </div>

                <div className="chat-suporte-input-group">
                    <label htmlFor="mensagem">
                        <FontAwesomeIcon icon={faCommentDots} className="icon" /> Mensagem
                    </label>
                    <div className="input-with-icon">
                        <textarea
                            id="mensagem"
                            value={mensagem}
                            onChange={(e) => setMensagem(e.target.value)}
                        />
                    </div>
                </div>

                {erro && <p className="chat-suporte-erro">{erro}</p>}
                {sucesso && <p className="chat-suporte-sucesso">{sucesso}</p>}

                <button type="submit" className="chat-suporte-button">Enviar Mensagem</button>
            </form>
        </div>
    );
};

export default ChatSuporte;
