import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdminAuthenticated, adminLogout } from '../../auth/auth';
import logo from '../../imagens/logo_menu_login.webp'; // Caminho correto da logo
import './suporteAdmin.css'; // Novo arquivo CSS adaptado
import ChatAdmin from './chatAdmin'; // Importando o novo componente de chat

const SuporteAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica se o administrador está autenticado
    if (!isAdminAuthenticated()) {
      navigate('/adminLogin');
    }
  }, [navigate]);

  const handleLogout = () => {
    adminLogout(); // Função para deslogar o administrador
  };

  return (
    <div className="suporte-admin-container">
      <div className="suporte-admin-header">
        <img src={logo} alt="Logo" className="suporte-admin-logo" />
        <div className="suporte-admin-user-info">
          <span>Bem-vindo(a), Admin</span>
          <button className="suporte-admin-button" onClick={handleLogout}>Sair</button>
        </div>
      </div>
      <div className="suporte-admin-content">
        <h1 className="suporte-admin-title-gradient">Suporte Administrativo</h1>
        <p className="suporte-admin-description">
          Aqui você pode acessar o suporte para questões administrativas.
        </p>

        <div className="suporte-admin-actions">
          <button className="suporte-admin-action-button">Entrar em contato com o Suporte</button>
          <button className="suporte-admin-action-button">Visualizar Tickets</button>
        </div>

        {/* Renderizando o componente de chat da administração */}
        <ChatAdmin />
      </div>
    </div>
  );
};

export default SuporteAdmin;
