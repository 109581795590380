import React, { useEffect } from 'react';
import './MinhaConta.css';
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom'; // Importa useNavigate
import { isAuthenticated, logout } from '../../auth/auth';
import PainelDeControle from './PainelDeControle'; // Importando o Painel de Controle
import TrocaDeSenha from './TrocaDeSenha'; // Importando o componente de Troca de Senha

const MinhaConta = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate(); // Hook para navegação

 useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="minha-conta-container">
      <div className="minha-conta-header">
        <img src={logo} alt="Logo" className="minha-conta-logo" />
        <div className="minha-conta-user-info">
          <span>{userEmail}</span>
          <button className="minha-conta-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="minha-conta-content">
        <h1 className="minha-conta-title-gradient">Minha Conta</h1>
        <p className="minha-conta-description">
          Nesta página, você pode visualizar e gerenciar suas informações pessoais, verificar o número de palavras disponíveis, e acompanhar outras estatísticas relevantes sobre o seu uso da plataforma.
        </p>
        <Link to="/menu" className="minha-conta-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>

        {/* Renderiza o Painel de Controle */}
        <PainelDeControle /> 

        {/* Renderiza o novo componente Troca de Senha */}
        <TrocaDeSenha />
      </div>
    </div>
  );
};

export default MinhaConta;
