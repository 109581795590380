import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { isAuthenticated, getUserEmail } from '../../auth/auth';
import './FacebookAdsDashboard.css';

const FacebookAdsDashboard = ({ setMetricsData }) => {
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [ads, setAds] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [campaignMetrics, setCampaignMetrics] = useState({
    objective: null,
    conversionLocation: null,
    budgetDaily: null,
    budgetLifetime: null,
    campaignType: null,
  });
  const [error, setError] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedAdSet, setSelectedAdSet] = useState(null);
  const [selectedAd, setSelectedAd] = useState(null);
  const [dateRange, setDateRange] = useState({ since: '', until: '' });
  const [datePreset, setDatePreset] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [expandedItems, setExpandedItems] = useState({});
  const [showDateSelector, setShowDateSelector] = useState(false);

  const userEmail = getUserEmail();

  // Definição do action_type desejado
  const ACTION_TYPE = 'onsite_conversion.messaging_conversation_started_7d';

  // Mapas para formatação dos objetivos e tipos de conversão
  const campaignObjectives = {
    'APP_INSTALLS': 'Instalações de Aplicativo',
    'BRAND_AWARENESS': 'Reconhecimento da Marca',
    'CONVERSIONS': 'Conversões',
    'EVENT_RESPONSES': 'Respostas a Eventos',
    'LEAD_GENERATION': 'Geração de Cadastros',
    'LINK_CLICKS': 'Cliques no Link',
    'MESSAGES': 'Mensagens',
    'OFFER_CLAIMS': 'Resgate de Ofertas',
    'PAGE_LIKES': 'Curtidas na Página',
    'POST_ENGAGEMENT': 'Envolvimento com a Publicação',
    'PRODUCT_CATALOG_SALES': 'Vendas do Catálogo de Produtos',
    'REACH': 'Alcance',
    'TRAFFIC': 'Tráfego',
    'VIDEO_VIEWS': 'Visualizações de Vídeo',
    'STORE_VISITS': 'Visitas à Loja',
    'ENGAGEMENT': 'Envolvimento',
    // Adicione outros objetivos conforme necessário
  };

  const destinationTypes = {
    'WEBSITE': 'Site',
    'APP': 'Aplicativo',
    'MESSENGER': 'Messenger',
    'WHATSAPP': 'WhatsApp',
    'INSTAGRAM_DIRECT': 'Instagram Direct',
    'PHONE_CALL': 'Chamada Telefônica',
    'STORE_VISIT': 'Visita à Loja',
    'UNDEFINED': 'Não especificado',
    // Adicione outros tipos conforme necessário
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      setError('Usuário não autenticado. Faça login primeiro.');
      return;
    }

    const existingToken = sessionStorage.getItem(`facebookToken_${userEmail}`);
    if (existingToken) {
      fetchAdAccounts(existingToken);
    }
  }, [userEmail]);

  const fetchAdAccounts = async (token = null) => {
    setLoading(true);
    try {
      const accessToken =
        token || sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        throw new Error('Token de acesso não encontrado.');
      }

      const response = await axios.get(
        'https://graph.facebook.com/v20.0/me/adaccounts',
        {
          params: {
            access_token: accessToken,
            fields: 'id,name',
          },
        }
      );
      setAccounts(response.data.data);
    } catch (error) {
      setError('Erro ao carregar contas de anúncio.');
      console.error('Erro ao buscar contas de anúncio:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaigns = async (accountId) => {
    setSelectedAccount(accountId);
    setSelectedCampaign(null);
    setCampaigns([]);
    setAdSets([]);
    setAds([]);
    setMetrics([]);
    setCampaignMetrics({
      objective: null,
      conversionLocation: null,
      budgetDaily: null,
      budgetLifetime: null,
      campaignType: null,
    });
    setLoading(true);
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${accountId}/campaigns`,
        {
          params: {
            access_token: sessionStorage.getItem(
              `facebookToken_${userEmail}`
            ),
            fields: 'id,name,objective,buying_type,is_campaign_budget_optimization',
          },
        }
      );
      setCampaigns(response.data.data);
      if (response.data.data.length > 0) {
        const firstCampaign = response.data.data[0];
        setSelectedCampaign(firstCampaign.id);
        await fetchAdSets(
          firstCampaign.id,
          firstCampaign.objective,
          firstCampaign.is_campaign_budget_optimization
        );
      }
    } catch (error) {
      setError('Erro ao carregar campanhas.');
      console.error('Erro ao buscar campanhas:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAdSets = async (campaignId, campaignObjective, isCBO) => {
    setSelectedAdSet(null);
    setAdSets([]);
    setAds([]);
    setMetrics([]);
    setCampaignMetrics((prev) => ({
      ...prev,
      objective:
        campaignObjectives[campaignObjective] || campaignObjective || 'Não especificado',
      campaignType: isCBO ? 'CBO' : 'ABO',
    }));
    setLoading(true);
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${campaignId}/adsets`,
        {
          params: {
            access_token: sessionStorage.getItem(
              `facebookToken_${userEmail}`
            ),
            fields: 'id,name,optimization_goal,promoted_object,destination_type,daily_budget,lifetime_budget',
          },
        }
      );
      setAdSets(response.data.data);
      if (response.data.data.length > 0) {
        const firstAdSet = response.data.data[0];
        setSelectedAdSet(firstAdSet.id);
        const conversionType = extractConversionType(firstAdSet);
        setCampaignMetrics((prev) => ({
          ...prev,
          conversionLocation: conversionType || 'Não especificado',
          budgetDaily: firstAdSet.daily_budget
            ? `R$ ${(firstAdSet.daily_budget / 100).toFixed(2)}`
            : 'R$ 0.00',
          budgetLifetime: firstAdSet.lifetime_budget
            ? `R$ ${(firstAdSet.lifetime_budget / 100).toFixed(2)}`
            : 'R$ 0.00',
        }));
        await fetchAds(firstAdSet.id);
      }
    } catch (error) {
      setError('Erro ao carregar conjuntos de anúncios.');
      console.error('Erro ao buscar conjuntos de anúncios:', error);
    } finally {
      setLoading(false);
    }
  };

  const extractConversionType = (adSet) => {
    if (adSet.destination_type) {
      return destinationTypes[adSet.destination_type] || adSet.destination_type;
    }
    if (adSet.promoted_object) {
      const promotedObject = adSet.promoted_object;
      if (promotedObject.pixel_id) return 'Conversão no Site';
      if (promotedObject.application_id) return 'Instalação de App';
      if (promotedObject.event_id) return 'Participação em Evento';
      if (promotedObject.page_id) return 'Envolvimento com Página';
      if (promotedObject.place_page_set_id) return 'Visitas à Loja';
      return 'Outro';
    }
    return 'Não especificado';
  };

  const fetchAds = async (adSetId) => {
    setSelectedAd(null);
    setAds([]);
    setMetrics([]);
    setLoading(true);

    try {
      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${adSetId}/ads`,
        {
          params: {
            access_token: sessionStorage.getItem(
              `facebookToken_${userEmail}`
            ),
            fields: 'id,name,creative{object_story_spec}',
          },
        }
      );
      const adsWithCreativeType = response.data.data.map((ad) => ({
        ...ad,
        creative_type: getCreativeType(ad.creative?.object_story_spec),
      }));
      setAds(adsWithCreativeType);
      if (adsWithCreativeType.length > 0) {
        const firstAd = adsWithCreativeType[0];
        setSelectedAd(firstAd.id);
        await fetchAdMetrics(firstAd.id);
      }
    } catch (error) {
      setError('Erro ao carregar anúncios.');
      console.error('Erro ao buscar anúncios:', error);
    } finally {
      setLoading(false);
    }
  };

  const getCreativeType = (objectStorySpec) => {
    if (!objectStorySpec) return 'Não especificado';
    if (objectStorySpec.video_data) return 'Vídeo';
    if (objectStorySpec.template_data?.multi_share_end_card) return 'Carrossel';
    if (objectStorySpec.link_data) return 'Imagem';
    return 'Não especificado';
  };

  const fetchAdMetrics = async (adId) => {
    setMetrics([]);
    setLoading(true);
    try {
      const accessToken = sessionStorage.getItem(
        `facebookToken_${userEmail}`
      );
      if (!accessToken) {
        throw new Error('Token de acesso não encontrado.');
      }

      const params = {
        access_token: accessToken,
        fields:
          'impressions,reach,frequency,spend,cpm,inline_link_click_ctr,inline_link_clicks,cost_per_inline_link_click,actions,cost_per_action_type,video_p75_watched_actions,video_thruplay_watched_actions',
      };

      if (datePreset) {
        params.date_preset = datePreset;
      } else if (dateRange.since && dateRange.until) {
        params.time_range = {
          since: dateRange.since,
          until: dateRange.until,
        };
      }

      const response = await axios.get(
        `https://graph.facebook.com/v20.0/${adId}/insights`,
        {
          params: params,
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: 'comma' }),
        }
      );

      console.log('Métricas recebidas:', response.data.data);
      const fetchedMetrics = response.data.data;

      const metricsWithDetails = fetchedMetrics.map((metric) => {
        const roiValue = metric.action_values
          ? metric.action_values.find((action) => action.action_type === 'roi')?.value
          : null;
        const roi = roiValue ? parseFloat(roiValue).toFixed(2) : '0.00';

        const totalResults = metric.actions
          ? metric.actions
              .filter((action) => action.action_type === ACTION_TYPE)
              .reduce((sum, action) => sum + parseInt(action.value, 10), 0)
          : 0;

        const costPerResultAction = metric.cost_per_action_type
          ? metric.cost_per_action_type.find((action) => action.action_type === ACTION_TYPE)
          : null;

        const costPerResult = costPerResultAction
          ? costPerResultAction.value
          : '0.00';

        const video3SecViewsAction = metric.actions
          ? metric.actions.find((action) => action.action_type === 'video_view')
          : null;
        const video3SecViews = video3SecViewsAction
          ? parseInt(video3SecViewsAction.value, 10)
          : 0;

        const video75ViewsAction = metric.video_p75_watched_actions
          ? metric.video_p75_watched_actions[0]
          : null;
        const video75Views = video75ViewsAction
          ? parseInt(video75ViewsAction.value, 10)
          : 0;

        const hookRate =
          metric.impressions && video3SecViews
            ? (video3SecViews / parseInt(metric.impressions, 10)) * 100
            : 0;

        const holdRate =
          metric.impressions && video75Views
            ? (video75Views / parseInt(metric.impressions, 10)) * 100
            : 0;

        return {
          ...metric,
          roi: `${roi}%`,
          totalResults: totalResults,
          costPerResult: costPerResult,
          link_click_ctr: metric.inline_link_click_ctr,
          link_click_cpc: metric.cost_per_inline_link_click,
          video3SecViews: video3SecViews,
          video75Views: video75Views,
          hookRate: hookRate,
          holdRate: holdRate,
        };
      });

      setMetrics(metricsWithDetails);

      // Atualização do setMetricsData para incluir as informações adicionais
      setMetricsData({
        campaign: { id: selectedCampaign, name: campaigns.find(c => c.id === selectedCampaign)?.name },
        adSet: { id: selectedAdSet, name: adSets.find(a => a.id === selectedAdSet)?.name },
        ad: { id: adId, name: ads.find(a => a.id === adId)?.name },
        metrics: metricsWithDetails,
        campaignObjective: campaignMetrics.objective,
        conversionType: campaignMetrics.conversionLocation,
        creativeType: ads.find(a => a.id === adId)?.creative_type,
        campaignType: campaignMetrics.campaignType,
      });
    } catch (error) {
      setError('Erro ao carregar métricas do anúncio.');
      console.error('Erro ao buscar métricas do anúncio:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    setDatePreset('');
    setDateRange({
      ...dateRange,
      [e.target.name]: e.target.value,
    });
  };

  const handleDatePresetClick = (preset) => {
    setDatePreset(preset);
    setDateRange({ since: '', until: '' });

    const today = new Date();
    let since = new Date();

    switch (preset) {
      case 'today':
        since = today;
        break;
      case 'yesterday':
        since.setDate(today.getDate() - 1);
        break;
      case 'last_7d':
        since.setDate(today.getDate() - 7);
        break;
      case 'last_30d':
        since.setDate(today.getDate() - 30);
        break;
      default:
        break;
    }

    setDateRange({
      since: since.toISOString().split('T')[0],
      until: today.toISOString().split('T')[0],
    });
  };

  const handleMetricClick = (metricName, metricValue) => {
    setModalContent(`${metricName}: ${metricValue}`);
    setShowModal(true);
  };

  const toggleExpand = (itemType, itemId) => {
    setExpandedItems((prev) => ({
      ...prev,
      [itemType]: prev[itemType] === itemId ? null : itemId,
    }));
  };

  const metricNames = {
    impressions: 'Impressões',
    reach: 'Alcance',
    frequency: 'Frequência',
    spend: 'Valor Usado',
    cpm: 'CPM',
    link_click_cpc: 'CPC de Cliques no Link',
    link_click_ctr: 'CTR de Cliques no Link',
    inline_link_clicks: 'Cliques no Link',
    totalResults: 'Resultados',
    costPerResult: 'Custo por Resultado',
    roi: 'Retorno sobre Investimento (ROI)',
    campaign_objective: 'Objetivo da Campanha',
    conversion_type: 'Tipo de Conversão',
    creative_type: 'Tipo de Criativo',
    hookRate: 'Hook Rate',
    holdRate: 'Hold Rate',
  };

  const formatMetricValue = (key, value) => {
    if (['spend', 'cpm', 'link_click_cpc', 'costPerResult'].includes(key)) {
      return value ? `R$ ${parseFloat(value).toFixed(2)}` : 'R$ 0.00';
    } else if (['link_click_ctr', 'hookRate', 'holdRate', 'roi'].includes(key)) {
      return value ? `${parseFloat(value).toFixed(2)}%` : '0.00%';
    } else if (key === 'frequency') {
      return value ? parseFloat(value).toFixed(2) : '0.00';
    } else if (
      ['totalResults', 'inline_link_clicks', 'impressions', 'reach'].includes(
        key
      )
    ) {
      return value ? parseInt(value, 10).toLocaleString() : '0';
    } else {
      return value || 'Não especificado';
    }
  };

  const metricsToDisplay = [
    'impressions',
    'reach',
    'frequency',
    'spend',
    'cpm',
    'inline_link_clicks',
    'link_click_ctr',
    'link_click_cpc',
    'totalResults',
    'costPerResult',
    'roi',
    'hookRate',
    'holdRate',
  ];

  return (
    <div className="fad-facebook-ads-dashboard">
      <div className="fad-menu-container">
        <div className="fad-menu-item">
          <h3>Contas</h3>
          <select
            onChange={(e) => fetchCampaigns(e.target.value)}
            className="fad-select"
            value={selectedAccount || ''}
          >
            <option value="">Selecione uma conta</option>
            {accounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.name}
              </option>
            ))}
          </select>
        </div>
        {selectedAccount && campaigns.length > 0 && (
          <div className="fad-menu-item">
            <h3>Campanhas</h3>
            <ul className="fad-list">
              {campaigns.map((campaign) => (
                <li
                  key={campaign.id}
                  className={`fad-list-item ${
                    expandedItems.campaign === campaign.id ? 'fad-expanded' : ''
                  } ${selectedCampaign === campaign.id ? 'fad-selected-item' : ''}`}
                >
                  <div
                    className="fad-list-item-header"
                    onClick={() => {
                      toggleExpand('campaign', campaign.id);
                      setSelectedCampaign(campaign.id);
                      fetchAdSets(
                        campaign.id,
                        campaign.objective,
                        campaign.is_campaign_budget_optimization
                      );
                    }}
                  >
                    {campaign.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {selectedCampaign && adSets.length > 0 && (
          <div className="fad-menu-item">
            <h3>Conjuntos de Anúncios</h3>
            <ul className="fad-list">
              {adSets.map((adSet) => (
                <li
                  key={adSet.id}
                  className={`fad-list-item ${
                    expandedItems.adSet === adSet.id ? 'fad-expanded' : ''
                  } ${selectedAdSet === adSet.id ? 'fad-selected-item' : ''}`}
                >
                  <div
                    className="fad-list-item-header"
                    onClick={() => {
                      toggleExpand('adSet', adSet.id);
                      setSelectedAdSet(adSet.id);
                      const conversionType = extractConversionType(adSet);
                      setCampaignMetrics((prev) => ({
                        ...prev,
                        conversionLocation: conversionType || 'Não especificado',
                        budgetDaily: adSet.daily_budget
                          ? `R$ ${(adSet.daily_budget / 100).toFixed(2)}`
                          : 'R$ 0.00',
                        budgetLifetime: adSet.lifetime_budget
                          ? `R$ ${(adSet.lifetime_budget / 100).toFixed(2)}`
                          : 'R$ 0.00',
                      }));
                      fetchAds(adSet.id);
                    }}
                  >
                    {adSet.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {selectedAdSet && ads.length > 0 && (
          <div className="fad-menu-item">
            <h3>Anúncios</h3>
            <ul className="fad-list">
              {ads.map((ad) => (
                <li
                  key={ad.id}
                  className={`fad-list-item ${
                    expandedItems.ad === ad.id ? 'fad-expanded' : ''
                  } ${selectedAd === ad.id ? 'fad-selected-item' : ''}`}
                >
                  <div
                    className="fad-list-item-header"
                    onClick={() => {
                      toggleExpand('ad', ad.id);
                      setSelectedAd(ad.id);
                      fetchAdMetrics(ad.id);
                    }}
                  >
                    {ad.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {selectedAd && (
        <div className="fad-date-selector">
          <button
            onClick={() => setShowDateSelector(!showDateSelector)}
            className="fad-button date-toggle-button"
          >
            {showDateSelector
              ? 'Esconder Intervalo de Datas'
              : 'Selecionar Intervalo de Datas'}
          </button>
          {showDateSelector && (
            <div className="fad-date-selector-content">
              <h3>Intervalo de Datas</h3>
              <div className="fad-date-inputs">
                <input
                  type="date"
                  name="since"
                  value={dateRange.since}
                  onChange={handleDateChange}
                  className="fad-input"
                />
                <input
                  type="date"
                  name="until"
                  value={dateRange.until}
                  onChange={handleDateChange}
                  className="fad-input"
                />
              </div>
              <div className="fad-date-preset-buttons">
                <button
                  onClick={() => handleDatePresetClick('today')}
                  className="fad-button small-button"
                >
                  Hoje
                </button>
                <button
                  onClick={() => handleDatePresetClick('yesterday')}
                  className="fad-button small-button"
                >
                  Ontem
                </button>
                <button
                  onClick={() => handleDatePresetClick('last_7d')}
                  className="fad-button small-button"
                >
                  Últimos 7 dias
                </button>
                <button
                  onClick={() => handleDatePresetClick('last_30d')}
                  className="fad-button small-button"
                >
                  Últimos 30 dias
                </button>
              </div>
            </div>
          )}
          <button
            className="fad-fetch-metrics-btn small-button"
            onClick={() => fetchAdMetrics(selectedAd)}
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Buscar Métricas'}
          </button>
        </div>
      )}

      {metrics.length > 0 && (
        <div className="fad-metrics-container">
          <h2>Métricas do Anúncio</h2>
          <div className="fad-metrics-grid">
            <div className="fad-metric-item">
              <h4>{metricNames.campaign_objective}</h4>
              <p>{campaignMetrics.objective || 'Não especificado'}</p>
            </div>
            <div className="fad-metric-item">
              <h4>{metricNames.conversion_type}</h4>
              <p>{campaignMetrics.conversionLocation || 'Não especificado'}</p>
            </div>
            <div className="fad-metric-item">
              <h4>{metricNames.creative_type}</h4>
              <p>
                {ads.find((ad) => ad.id === selectedAd)?.creative_type ||
                  'Não especificado'}
              </p>
            </div>
            <div className="fad-metric-item">
              <h4>Orçamento Diário</h4>
              <p>{campaignMetrics.budgetDaily}</p>
            </div>
            <div className="fad-metric-item">
              <h4>Orçamento Total</h4>
              <p>{campaignMetrics.budgetLifetime}</p>
            </div>
            <div className="fad-metric-item">
              <h4>Tipo da Campanha</h4>
              <p>{campaignMetrics.campaignType || 'Não especificado'}</p>
            </div>
            {metrics.map((metric, index) => (
              <React.Fragment key={index}>
                {metricsToDisplay.map((key) => {
                  const value = metric[key];
                  if (value !== undefined) {
                    return (
                      <div className="fad-metric-item" key={key}>
                        <h4>{metricNames[key]}</h4>
                        <p>{formatMetricValue(key, value)}</p>
                      </div>
                    );
                  }
                  return null;
                })}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      {error && <p className="fad-error-message">{error}</p>}

      {showModal && (
        <div className="fad-modal">
          <div className="fad-modal-content">
            <h3>Detalhes da Métrica</h3>
            <p>{modalContent}</p>
            <button onClick={() => setShowModal(false)} className="fad-button">
              Fechar
            </button>
          </div>
        </div>
      )}

      {loading && <div className="fad-loading-indicator">Carregando...</div>}
    </div>
  );
};

export default FacebookAdsDashboard;
