import React, { useEffect } from 'react';
import './Suporte.css';
import logo from '../../imagens/logo_menu_login.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../../auth/auth';
import ChatSuporte from './ChatSuporte';
import RelatorioSuporte from './RelatorioSuporte'; // Importa o componente de Relatório Suporte

const Suporte = () => {
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();


useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // Redireciona o usuário não autenticado para a tela de login
    }
  }, [navigate]);

  return (
    <div className="suporte-container">
      <div className="suporte-header">
        <img src={logo} alt="Logo" className="suporte-logo" />
        <div className="suporte-user-info">
          <span>{userEmail}</span>
          <button className="suporte-button" onClick={logout}>Sair</button>
        </div>
      </div>
      <div className="suporte-content">
        <h1 className="suporte-title-gradient">Suporte</h1>
        <p className="suporte-description">
          Precisa de ajuda? Estamos aqui para ajudá-lo a solucionar qualquer problema que você possa ter.
        </p>
        <Link to="/menu" className="suporte-button-menu" style={{ textDecoration: 'none' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o Menu
        </Link>
        <ChatSuporte /> {/* Renderiza o componente de Chat Suporte */}
        <RelatorioSuporte /> {/* Renderiza o componente de Relatório Suporte */}
      </div>
    </div>
  );
};

export default Suporte;
