import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faUsers, faCheckCircle, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import './BlocoDeInformacoes.css';

function BlocoDeInformacoes() {
  const [formData, setFormData] = useState({
    produto: '',
    publicoAlvo: '',
    beneficios: '',
    condicoesPagamento: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCopy = () => {
    const dadosParaCopiar = `
      Produto: ${formData.produto}\n
      Público-Alvo: ${formData.publicoAlvo.replace(/Exemplo:.*/g, '')}\n
      Benefícios: ${formData.beneficios}\n
      Condições de Pagamento: ${formData.condicoesPagamento}
    `;

    navigator.clipboard.writeText(dadosParaCopiar)
      .then(() => {
        console.log("Dados copiados para a área de transferência!");
      })
      .catch((err) => {
        console.error("Erro ao copiar dados:", err);
      });
  };

  return (
    <div className="bloco-informacoes-container">
      <h2 className="bloco-informacoes-titulo">Preencha as Informações do Produto</h2>
      <div className="bloco-informacoes-campo">
        <label htmlFor="produto"><FontAwesomeIcon icon={faBox} /> Produto:</label>
        <input
          type="text"
          id="produto"
          name="produto"
          value={formData.produto}
          onChange={handleInputChange}
          className="bloco-informacoes-input"
        />
      </div>
      <div className="bloco-informacoes-campo">
        <label htmlFor="publicoAlvo"><FontAwesomeIcon icon={faUsers} /> Público-Alvo:</label>
        <input
          type="text"
          id="publicoAlvo"
          name="publicoAlvo"
          placeholder="Exemplo: Jovens empreendedores"
          value={formData.publicoAlvo}
          onChange={handleInputChange}
          className="bloco-informacoes-input"
        />
      </div>
      <div className="bloco-informacoes-campo">
        <label htmlFor="beneficios"><FontAwesomeIcon icon={faCheckCircle} /> Benefícios:</label>
        <textarea
          id="beneficios"
          name="beneficios"
          value={formData.beneficios}
          onChange={handleInputChange}
          className="bloco-informacoes-textarea"
        />
      </div>
      <div className="bloco-informacoes-campo">
        <label htmlFor="condicoesPagamento"><FontAwesomeIcon icon={faMoneyBillWave} /> Condições de Pagamento:</label>
        <textarea
          id="condicoesPagamento"
          name="condicoesPagamento"
          value={formData.condicoesPagamento}
          onChange={handleInputChange}
          className="bloco-informacoes-textarea"
        />
      </div>
      <button className="bloco-informacoes-botao" onClick={handleCopy}>
        Copiar informações para colar no chat
      </button>
    </div>
  );
}

export default BlocoDeInformacoes;
