import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat-copy.css';
import { FaTrash, FaPlusCircle, FaPaperPlane, FaUserCircle, FaDownload } from 'react-icons/fa'; 
import { getUserEmail } from '../../auth/auth'; // Removeu getAuthToken
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { inicializarPalavras, consumirPalavras, verificarPalavras } from '../../auth/palavraControle';
import { startMonitoring, stopMonitoring } from '../../auth/userActivityMonitor'; 
import { startSync, stopSync } from '../../auth/syncWords';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [chatBloqueado, setChatBloqueado] = useState(false); // Estado para controlar o bloqueio do chat
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const chatMessagesRef = useRef(null);

  const userEmail = getUserEmail(); // Usa apenas getUserEmail
  const MAX_REQUESTS_PER_MINUTE = 10;

  useEffect(() => {
    const storedMessages = localStorage.getItem(`chatHistory_${userEmail}`);
    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`); // Usa a chave específica para o e-mail

    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }

    // Sempre que abrir o chat, verificar se o saldo é zero e bloquear o chat se necessário
    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
    } else {
      setChatBloqueado(false); // Desbloquear se houver saldo
    }

    startMonitoring();
    startSync();
    scrollToBottom();

    return () => {
      stopMonitoring();
      stopSync();
    };
  }, [userEmail]);

  useEffect(() => {
    const textarea = inputRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setCharCount(textarea.value.length);
  }, [input]);

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  const saveMessages = (messages) => {
    localStorage.setItem(`chatHistory_${userEmail}`, JSON.stringify(messages));
    scrollToBottom();
  };

  const canMakeRequest = () => {
    const requestTimestamps = JSON.parse(localStorage.getItem(`requestTimestamps_${userEmail}`)) || [];
    const now = Date.now();
    const oneMinuteAgo = now - 60000;

    const recentRequests = requestTimestamps.filter(timestamp => timestamp > oneMinuteAgo);
    localStorage.setItem(`requestTimestamps_${userEmail}`, JSON.stringify(recentRequests));

    return recentRequests.length < MAX_REQUESTS_PER_MINUTE;
  };

  const recordRequestTimestamp = () => {
    const requestTimestamps = JSON.parse(localStorage.getItem(`requestTimestamps_${userEmail}`)) || [];
    requestTimestamps.push(Date.now());
    localStorage.setItem(`requestTimestamps_${userEmail}`, JSON.stringify(requestTimestamps));
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const contarTokensPorPalavra = (palavra) => {
    const length = palavra.length;
    if (length <= 4) return 1;
    if (length <= 8) return 2;
    return 3;
  };

  const calcularTokensUsados = (message) => {
    return message
      .trim()
      .split(/\s+/)
      .reduce((total, palavra) => total + contarTokensPorPalavra(palavra), 0);
  };

  // Função para verificar o saldo de palavras diretamente do localStorage para o e-mail específico
  const verificarSaldoLocalStorage = () => {
    const storedWords = localStorage.getItem(`${userEmail}-palavrasDisponiveis`); // Consulta agora com base no e-mail específico
    if (storedWords && parseInt(storedWords) <= 0) {
      setChatBloqueado(true);
      return false; // Não permite o uso do chat
    }
    return true;
  };

  const handleSend = async (messageToSend = null) => {
    const message = messageToSend || input;

    if (message.trim() === '') return;

    const tokensUsados = calcularTokensUsados(message);

    // Verifica o saldo diretamente no localStorage antes de continuar, associado ao e-mail específico
    if (!verificarSaldoLocalStorage()) {
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
      return; // Bloqueia o envio de novas mensagens
    }

    let saldoAtual = parseInt(localStorage.getItem(`${userEmail}-palavrasDisponiveis`), 10);
    if (isNaN(saldoAtual)) saldoAtual = 0;

    if (saldoAtual < tokensUsados) {
      alert('Você não tem tokens suficientes para enviar essa mensagem.');
      return;
    }

    if (!canMakeRequest()) {
      alert('Você atingiu o limite de requisições por minuto. Por favor, aguarde.');
      return;
    }

    const userMessage = { text: message, sender: 'user' };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    saveMessages(updatedMessages);

    saldoAtual -= tokensUsados;
    localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual); // Armazena o saldo atualizado no localStorage para o e-mail específico

    // Se o saldo chegar a zero após o envio da mensagem, bloquear o chat
    if (saldoAtual <= 0) {
      setChatBloqueado(true);
      alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
    }

    await consumirPalavras(userEmail, tokensUsados);

    setIsTyping(true);
    recordRequestTimestamp();

    try {
      let threadId = localStorage.getItem(`chatThreadId_${userEmail}`);

      if (!threadId) {
        const threadResponse = await axios.post(
          'https://api.openai.com/v1/threads',
          {},
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
        threadId = threadResponse.data.id;
        localStorage.setItem(`chatThreadId_${userEmail}`, threadId);
      }

      await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          role: 'user',
          content: message
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      setInput('');

      const runResponse = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        {
          assistant_id: process.env.REACT_APP_OPENAI_ASSISTANT_ID
        },
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      let runStatus = 'queued';
      while (runStatus !== 'completed') {
        await new Promise(resolve => setTimeout(resolve, 2000));
        const statusResponse = await axios.get(
          `https://api.openai.com/v1/threads/${threadId}/runs/${runResponse.data.id}`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
        runStatus = statusResponse.data.status;
      }

      const messagesResponse = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
            'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
            'OpenAI-Beta': 'assistants=v2'
          }
        }
      );

      const assistantMessage = messagesResponse.data.data.find(
        msg => msg.role === 'assistant'
      );

      setIsTyping(false);

      if (assistantMessage) {
        const assistantText = assistantMessage.content
          .map(content => content.text?.value || '')
          .join(' ');

        const updatedMessagesWithResponse = [
          ...updatedMessages,
          { text: assistantText, sender: 'assistant' }
        ];
        setMessages(updatedMessagesWithResponse);
        saveMessages(updatedMessagesWithResponse);

        const tokensResposta = calcularTokensUsados(assistantText);
        saldoAtual -= tokensResposta;
        localStorage.setItem(`${userEmail}-palavrasDisponiveis`, saldoAtual); // Atualiza o saldo no localStorage para o e-mail específico

        // Se o saldo chegar a zero após a resposta do assistente, bloquear o chat
        if (saldoAtual <= 0) {
          setChatBloqueado(true);
          alert('Seu saldo de palavras acabou! Aguarde a próxima renovação.');
        }

        await consumirPalavras(userEmail, tokensResposta);

      } else {
        const errorMessage = [
          ...updatedMessages,
          { text: 'Erro ao processar a solicitação.', sender: 'assistant' }
        ];
        setMessages(errorMessage);
        saveMessages(errorMessage);
      }

    } catch (error) {
      console.error('Erro ao comunicar com o assistente:', error.response ? error.response.data : error.message);
      const errorMessage = [
        ...messages,
        { text: 'Erro ao processar a solicitação.', sender: 'assistant' }
      ];
      setMessages(errorMessage);
      saveMessages(errorMessage);
    }
  };

  const handleDeleteConversation = async () => {
    const threadId = localStorage.getItem(`chatThreadId_${userEmail}`);

    if (threadId) {
      try {
        await axios.delete(
          `https://api.openai.com/v1/threads/${threadId}`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'OpenAI-Organization': process.env.REACT_APP_OPENAI_ORG,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );
      } catch (error) {
        console.error('Erro ao excluir a thread no servidor:', error.response ? error.response.data : error.message);
      }
    }

    // Remover apenas o histórico de conversa e o ID da thread
    localStorage.removeItem(`chatHistory_${userEmail}`);
    localStorage.removeItem(`chatThreadId_${userEmail}`);
    setMessages([]);
  };

  const handleNewConversation = () => {
    handleDeleteConversation();
  };

  const handleDownloadDocx = (message) => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: message,
                  font: isMobile() ? 'Arial' : 'Calibri',
                  size: isMobile() ? 18 : 24,
                  break: 1,
                }),
              ],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "chat-response.docx");
    });
  };

  return (
    <div className="unique-chat-container">
      <div className="unique-chat-header">
        <div className="unique-chat-logo"></div>
        <h2>A Nossa [IA] vai criar copy para seus anúncios</h2>
        <div className="unique-chat-buttons">
          <div className="unique-chat-button" title="Nova Conversa">
            <FaPlusCircle onClick={handleNewConversation} />
          </div>
          <div className="unique-chat-button" title="Excluir Conversa">
            <FaTrash onClick={handleDeleteConversation} />
          </div>
        </div>
      </div>
      <div className="unique-chat-messages" ref={chatMessagesRef}>
        {messages.map((msg, index) => (
          <div key={index} className={`unique-chat-message ${msg.sender}`}>
            {msg.sender === 'user' ? <FaUserCircle className="unique-message-icon" /> : <div className="unique-message-icon"></div>}
            <span>{msg.text}</span>
            {msg.sender === 'assistant' && (
              <button className="unique-chat-download-button" onClick={() => handleDownloadDocx(msg.text)}>
                <FaDownload />
              </button>
            )}
          </div>
        ))}
        {isTyping && (
          <div className="unique-chat-message assistant typing">
            <div className="unique-message-icon"></div>
            <span>O assistente está digitando...</span>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="unique-chat-input-container">
        {/* Bloqueia o input se o chat estiver bloqueado */}
        <textarea
          ref={inputRef}
          className="unique-chat-text-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => !chatBloqueado && e.key === 'Enter' && !e.shiftKey && handleSend()} // Não permite envio se o chat estiver bloqueado
          placeholder={chatBloqueado ? "Seu saldo de palavras acabou. Aguarde a renovação." : "Digite sua mensagem..."}
          disabled={chatBloqueado} // Desabilita o input se o chat estiver bloqueado
        />
        <span className="char-count">{charCount}</span>
        <button className="unique-chat-send-button" onClick={() => !chatBloqueado && handleSend()} disabled={chatBloqueado}>
          <FaPaperPlane />
        </button>
      </div>
    </div>
  );
};

export default Chat;
