import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './paginas/menu/Menu';
import Cadastro from './paginas/Cadastro';
import Login from './paginas/Login';
import RecuperarSenha from './paginas/RecuperarSenha';
import LucroAlvo from './paginas/lucroAlvo/LucroAlvo';
import PlanejadorProsperidade from './paginas/planejadorProsperidade/PlanejadorProsperidade';
import AnaliseMetricas from './paginas/AnaliseMetricas/AnaliseMetricas';
import EstudioCriacao from './paginas/EstudioCriacao/EstudioCriacao';
import CriadorDeTextosPersuasivos from './paginas/Criador-deTextos-Persuasivos/CriadorDeTextosPersuasivos';
import ConversaoMagica from './paginas/ConversaoMagica/ConversaoMagica';
import FunilVendasWhatsApp from './paginas/funil/funil';
import MapaDigital from './paginas/mapaDigital/MapaDigital';
import MinhaConta from './paginas/minhaConta/MinhaConta';
import Suporte from './paginas/suporte/Suporte';
import PoliticaPublicidade from './paginas/PoliticaPublicidade'; // Adicionando a rota da Política de Publicidade

/* Importações para Admin */
import AdminLogin from './paginas/admin/adminLogin';
import PainelAdmin from './paginas/admin/painelAdmin'; // Corrigindo o caminho e importação
import AdminUsuario from './paginas/admin/adminUsuario'; // Nova importação para a página de Admin Usuário
import SuporteAdmin from './paginas/admin/suporteAdmin'; // Nova importação para a página de Suporte Admin

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/menu" element={<Menu />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recuperar-senha" element={<RecuperarSenha />} />
        <Route path="/lucro-alvo" element={<LucroAlvo />} />
        <Route path="/planejador-prosperidade" element={<PlanejadorProsperidade />} />
        <Route path="/analise-metricas" element={<AnaliseMetricas />} />
        <Route path="/estudio-criacao" element={<EstudioCriacao />} />
        <Route path="/criador-de-textos-persuasivos" element={<CriadorDeTextosPersuasivos />} />
        <Route path="/conversao-magica" element={<ConversaoMagica />} />
        <Route path="/funil-vendas-whatsapp" element={<FunilVendasWhatsApp />} />
        <Route path="/mapa-digital" element={<MapaDigital />} />
        <Route path="/minha-conta" element={<MinhaConta />} />
        <Route path="/suporte" element={<Suporte />} />
        <Route path="/politica-de-publicidade" element={<PoliticaPublicidade />} /> {/* Adicionando a rota da Política de Publicidade */}
        
        {/* Rotas de administração */}
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/painelAdmin" element={<PainelAdmin />} />
        <Route path="/admin-usuarios" element={<AdminUsuario />} /> {/* Rota para a página AdminUsuario */}
        <Route path="/suporte-admin" element={<SuporteAdmin />} /> {/* Rota para a página SuporteAdmin */}

        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
