import CryptoJS from 'crypto-js';
import axios from 'axios';
import { inicializarPalavras } from './palavraControle'; // Importa a função de injeção de palavras
import { verificarRenovacaoPalavras } from './syncWords'; // Importa a função de verificação de renovação

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const ADMIN_SECRET_KEY = process.env.REACT_APP_ADMIN_TOKEN_SECRET; // Nova chave para administradores
const SESSION_DURATION = 60 * 60 * 1000; // 1 hora
const BASEROW_TOKEN = process.env.REACT_APP_BASEROW_USER_ONLINE_TOKEN;
const TABLE_ID = process.env.REACT_APP_BASEROW_USER_ONLINE_TABLE_ID;
const BASE_URL = 'https://api.baserow.io/api/database/rows/table/';

// Função para obter a hora atual no fuso horário de São Paulo
const getBrazilTime = () => {
  const now = new Date(); // Obtém o horário do servidor
  const offset = -3; // UTC-3 para São Paulo
  const brazilTime = new Date(now.getTime() + offset * 60 * 60 * 1000); // Aplica o offset ao horário do servidor
  return brazilTime.toISOString(); // Retorna o horário em formato ISO
};

// Verifica se o usuário está autenticado e se a sessão ainda é válida
export const isAuthenticated = () => {
  const encryptedToken = localStorage.getItem('authToken');
  const encryptedLoginTime = localStorage.getItem('loginTime');

  if (!encryptedToken || !encryptedLoginTime) {
    return false;
  }

  const token = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const loginTime = parseInt(CryptoJS.AES.decrypt(encryptedLoginTime, SECRET_KEY).toString(CryptoJS.enc.Utf8), 10);

  const currentTime = Date.now();
  const timeElapsed = currentTime - loginTime;

  if (timeElapsed > SESSION_DURATION) {
    logout();
    return false;
  }

  return token !== null;
};

// Verifica se o administrador está autenticado
export const isAdminAuthenticated = () => {
  const encryptedAdminToken = localStorage.getItem('adminAuthToken');
  const encryptedAdminLoginTime = localStorage.getItem('adminLoginTime');

  if (!encryptedAdminToken || !encryptedAdminLoginTime) {
    return false;
  }

  const adminToken = CryptoJS.AES.decrypt(encryptedAdminToken, ADMIN_SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const adminLoginTime = parseInt(CryptoJS.AES.decrypt(encryptedAdminLoginTime, ADMIN_SECRET_KEY).toString(CryptoJS.enc.Utf8), 10);

  const currentTime = Date.now();
  const timeElapsed = currentTime - adminLoginTime;

  if (timeElapsed > SESSION_DURATION) {
    adminLogout();
    return false;
  }

  return adminToken !== null;
};

// Função para verificar se o usuário já existe na tabela, remover e reinserir ele com os dados atualizados
const reinsertUserOnlineStatus = async (email, name) => {
  try {
    // Primeiro, buscamos o usuário na tabela pelo e-mail
    const response = await axios.get(`${BASE_URL}${TABLE_ID}/?user_field_names=true`, {
      headers: {
        Authorization: `Token ${BASEROW_TOKEN}`,
      },
    });

    const existingUser = response.data.results.find(user => user['E-mail'] === email);
    const currentBrazilTime = getBrazilTime(); // Obtém o horário no fuso de São Paulo

    if (existingUser) {
      // Se o usuário existir, removemos ele primeiro
      await axios.delete(
        `${BASE_URL}${TABLE_ID}/${existingUser.id}/`,
        {
          headers: {
            Authorization: `Token ${BASEROW_TOKEN}`,
          },
        }
      );
      console.log('Usuário removido para reinserção.');
    }

    // Agora, reinserimos o usuário com os dados atualizados
    await axios.post(
      `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
      {
        'Nome': name,
        'E-mail': email,
        'Atividade': true,
        'Última atividade': currentBrazilTime, // Atualiza o horário
      },
      {
        headers: {
          Authorization: `Token ${BASEROW_TOKEN}`,
        },
      }
    );
    console.log('Usuário reinserido com dados atualizados.');
  } catch (error) {
    console.error('Erro ao registrar ou atualizar usuário online no Baserow:', error);
  }
};

// Função de login para usuários comuns
export const login = async (token, email, name) => {
  const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
  const encryptedLoginTime = CryptoJS.AES.encrypt(Date.now().toString(), SECRET_KEY).toString();

  localStorage.setItem('authToken', encryptedToken);
  localStorage.setItem('userEmail', email); 
  localStorage.setItem('userName', name);
  localStorage.setItem('loginTime', encryptedLoginTime);

  // Chama a função para reinserir o usuário na tabela
  await reinsertUserOnlineStatus(email, name);

  try {
    // Injeção de palavras se for novo usuário
    await inicializarPalavras(email);

    // Verificação de renovação de palavras
    await verificarRenovacaoPalavras();
  } catch (error) {
    console.error('Erro ao injetar ou verificar renovação de palavras:', error);
  }
};

// Função de login para administradores
export const adminLogin = async (adminToken, email, name) => {
  const encryptedAdminToken = CryptoJS.AES.encrypt(adminToken, ADMIN_SECRET_KEY).toString();
  const encryptedAdminLoginTime = CryptoJS.AES.encrypt(Date.now().toString(), ADMIN_SECRET_KEY).toString();

  localStorage.setItem('adminAuthToken', encryptedAdminToken);
  localStorage.setItem('adminEmail', email); 
  localStorage.setItem('adminName', name);
  localStorage.setItem('adminLoginTime', encryptedAdminLoginTime);
};

// Função de logout para usuários comuns
export const logout = async () => {
  const email = getUserEmail();
  try {
    // Busca o ID da linha correspondente ao email do usuário no Baserow
    const response = await axios.get(
      `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
      {
        headers: {
          Authorization: `Token ${BASEROW_TOKEN}`,
        },
      }
    );

    // Encontra o ID da linha correspondente ao email
    const userRow = response.data.results.find(user => user['E-mail'] === email);

    if (userRow) {
      // Exclui a linha do usuário no Baserow
      await axios.delete(
        `${BASE_URL}${TABLE_ID}/${userRow.id}/`,
        {
          headers: {
            Authorization: `Token ${BASEROW_TOKEN}`,
          },
        }
      );
      console.log('Usuário removido com sucesso');
    } else {
      console.log('Usuário não encontrado na tabela');
    }
  } catch (error) {
    console.error('Erro ao remover usuário do Baserow:', error);
  }

  // Limpa o localStorage e redireciona
  localStorage.removeItem('authToken');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userName');
  localStorage.removeItem('loginTime');
  window.location.href = '/';
};

// Função de logout para administradores
export const adminLogout = () => {
  localStorage.removeItem('adminAuthToken');
  localStorage.removeItem('adminEmail');
  localStorage.removeItem('adminName');
  localStorage.removeItem('adminLoginTime');
  window.location.href = '/adminlogin';
};

// Função para obter o email do administrador logado
export const getAdminEmail = () => {
  return localStorage.getItem('adminEmail');
};

// Função para obter o email do usuário comum logado
export const getUserEmail = () => {
  return localStorage.getItem('userEmail');
};
