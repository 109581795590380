import React, { useEffect, useState } from 'react';
import { logout, isAuthenticated } from '../../auth/auth';
import { useNavigate, Link } from 'react-router-dom';
import './Menu.css';
import logo from '../../imagens/logo_menu_login.webp';
import axios from 'axios';
import MenuVertical from './MenuVertical';

// Importando os arquivos necessários para a notificação
import welcomeSound from '../../assets/sounds/welcome.MP3';
import mensagens from '../../assets/mensagens.json';

// Importando as imagens para o menu
import analiseMetricasImg from '../../imagens/Analise-de-Metricas.webp';
import copyMetaADSImg from '../../imagens/Copy-para-Meta-ADS.webp';
import copyRemarketingImg from '../../imagens/copy-para-remarketing.webp';
import funilVendasImg from '../../imagens/Funil-de-Vendas.webp';
import guiaInvestimentoImg from '../../imagens/Guia-de-Investimento-Facil.webp';
import headlinesImg from '../../imagens/Headlines.webp';
import metaLucroImg from '../../imagens/Meta-de-Lucro.webp';
import provaSocialImg from '../../imagens/Prova-Social.webp';
import scriptVendasImg from '../../imagens/Script-de-Vendas-para-WhatsApp.webp';
import estudioCriacaoImg from '../../imagens/Estudio-de-Criacao.webp';
import mapaDigitalImg from '../../imagens/Mapa-do-Digital.webp';
import taxaConversaoImg from '../../imagens/Taxa-de-Conversao-no-WhatsApp.webp';


// Importando as novas imagens para o menu
import criadorCriativoImg from '../../imagens/criador-criativo.webp';
import minhaContaImg from '../../imagens/minha-conta.webp';
import personaImg from '../../imagens/persona.webp';
import suporteClienteImg from '../../imagens/suporte-cliente.webp';

const Menu = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [userName, setUserName] = useState('');
  const [randomMessage, setRandomMessage] = useState('');

  useEffect(() => {
    const fetchUserName = async () => {
      const email = localStorage.getItem('userEmail');
      try {
        const response = await axios.get(`https://api.baserow.io/api/database/rows/table/${process.env.REACT_APP_MENU_TABLE_ID}/?user_field_names=true`, {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_MENU_TOKEN}`,
          },
        });

        const usuarios = response.data.results;
        const usuario = usuarios.find((usuario) => usuario['E-mail'] === email);

        if (usuario) {
          setUserName(usuario.Nome);
        }
      } catch (error) {
        console.error('Erro ao buscar o nome do usuário:', error);
      }
    };

    if (isAuthenticated()) {
      fetchUserName();
      const lastLoginTime = localStorage.getItem('lastLoginTime');
      const currentTime = new Date().getTime();
      const tenMinutes = 10 * 60 * 1000;

      if (!lastLoginTime || currentTime - lastLoginTime > tenMinutes) {
        localStorage.setItem('lastLoginTime', currentTime);
        setShowMessage(true);

        const randomIndex = Math.floor(Math.random() * mensagens.length);
        setRandomMessage(mensagens[randomIndex]);
      }
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const playWelcomeSound = () => {
    const audio = new Audio(welcomeSound);
    audio.play().catch(error => console.error('Erro ao tocar o som:', error));
  };

  useEffect(() => {
    if (showMessage) {
      playWelcomeSound();
    }
  }, [showMessage]);

  const handleLogout = () => {
    logout();
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const userEmail = localStorage.getItem('userEmail');

  return (
    <div className="menu-container-unique">
      <MenuVertical />
      {showMessage && (
        <div className="message-popup-unique">
          <h2>Seja muito bem-vindo(a), <span className="user-name-unique">{userName}</span>!</h2>
          <p>{randomMessage} <span className="highlight-unique">Esta mensagem é para você!</span></p>
          <button className="close-btn-unique" onClick={handleCloseMessage}>Fechar</button>
          <p className="footer-message-unique">Você nasceu para prosperar!🚀</p>
        </div>
      )}
      <div className="header-unique">
        <img src={logo} alt="Logo" className="menu-logo-unique" />
        <div className="user-info-unique">
          <span>{userEmail}</span>
          <button onClick={handleLogout}>Sair</button>
        </div>
      </div>
      <div className="content-unique">
        <h1 className="title-gradient-unique">O Caminho para a Prosperidade Digital</h1>
        <p className="description-unique">"Bem-vindo! Aqui você encontra as ferramentas essenciais para iniciar sua jornada digital e alcançar a prosperidade."</p>

        {/* Categoria Financeiro */}
        <div className="category-title-unique">Financeiro</div>
        <div className="menu-items-unique">
          <div className="menu-item-unique">
            <img src={metaLucroImg} alt="Meta de Lucro" className="menu-img" />
            <Link to="/lucro-alvo" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={guiaInvestimentoImg} alt="Guia de Investimento Fácil" className="menu-img" />
            <Link to="/planejador-prosperidade" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={taxaConversaoImg} alt="Taxa de Conversão no WhatsApp" className="menu-img" />
            <Link to="/conversao-magica" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={funilVendasImg} alt="Funil de Vendas" className="menu-img" />
            <Link to="/funil-vendas-whatsapp" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={analiseMetricasImg} alt="Análise de Métricas" className="menu-img" />
            <Link to="/Analise-Metricas" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
        </div>

        {/* Categoria Gerador de Conversão */}
        <div className="category-title-unique">Gerador de Conversão</div>
        <div className="menu-items-unique">
          <div className="menu-item-unique">
            <img src={copyMetaADSImg} alt="Copy para Meta ADS" className="menu-img" />
            <Link to="/criador-de-textos-persuasivos" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={scriptVendasImg} alt="Script de Vendas para WhatsApp" className="menu-img" />
            <Link to="/script-vendas-whatsapp" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={copyRemarketingImg} alt="Copy para Remarketing" className="menu-img" />
            <Link to="/remarketing" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={headlinesImg} alt="Headlines" className="menu-img" />
            <Link to="/headlines" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={provaSocialImg} alt="Prova Social" className="menu-img" />
            <Link to="/prova" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
        </div>

        <div className="menu-items-unique">
          <div className="menu-item-unique">
            <img src={estudioCriacaoImg} alt="Estúdio de Criação" className="menu-img" />
            <Link to="/Estudio-Criacao" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={mapaDigitalImg} alt="Mapa do Digital" className="menu-img" />
            <Link to="/mapa-digital" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={criadorCriativoImg} alt="criadorCriativo" className="menu-img" />
            <Link to="/mapa-digital" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={personaImg} alt="persona" className="menu-img" />
            <Link to="/mapa-digital" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          
        </div>
         {/* Categoria Administração */}
        <div className="category-title-unique">Administração</div>
        <div className="menu-items-unique">
          <div className="menu-item-unique">
            <img src={minhaContaImg} alt="Minha Conta" className="menu-img" />
            <Link to="/minha-conta" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
          <div className="menu-item-unique">
            <img src={suporteClienteImg} alt="Suporte ao Cliente" className="menu-img" />
            <Link to="/suporte" className="no-underline-unique"><button className="btn-unique">Acesse aqui</button></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
