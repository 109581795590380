import axios from 'axios';
import { getUserEmail } from './auth';
import { atualizarSaldoPalavras } from './palavraControle';
import { DateTime } from 'luxon';

const BASE_URL = 'https://api.baserow.io/api/database/rows/table/';
const API_TOKEN = process.env.REACT_APP_BASEROW_API_TOKEN_PALAVRA_CONTROLE;
const TABLE_ID = process.env.REACT_APP_BASEROW_TABLE_ID_PALAVRA_CONTROLE;

let syncInterval; // Variável para armazenar o ID do intervalo

// Função para verificar e renovar palavras
export const verificarRenovacaoPalavras = async () => {
    const userEmail = getUserEmail();

    if (!userEmail) {
        console.error("Usuário não autenticado.");
        return;
    }

    try {
        // Obter a data atual do servidor e converter para horário de São Paulo
        const serverTime = DateTime.utc();
        const brazilTime = serverTime.setZone('America/Sao_Paulo');

        const response = await axios.get(
            `${BASE_URL}${TABLE_ID}/?user_field_names=true`,
            {
                headers: {
                    Authorization: `Token ${API_TOKEN}`,
                },
            }
        );

        const usuario = response.data.results.find(row => row.email === userEmail);

        if (usuario) {
            const dataRenovacao = DateTime.fromISO(usuario.data_renovacao_palavras, { zone: 'America/Sao_Paulo' });
            const diffInDays = brazilTime.diff(dataRenovacao, 'days').days;

            // Verifica se passaram 30 dias desde a última renovação
            if (diffInDays >= 30) {
                // Atualiza o saldo de palavras para 300.000
                await atualizarSaldoPalavras(userEmail, 300000);

                // Atualiza a data de renovação para hoje
                await axios.patch(
                    `${BASE_URL}${TABLE_ID}/${usuario.id}/?user_field_names=true`,
                    {
                        data_renovacao_palavras: brazilTime.toISO(),
                    },
                    {
                        headers: {
                            Authorization: `Token ${API_TOKEN}`,
                        },
                    }
                );

                console.log(`Palavras renovadas para o usuário ${userEmail}. Nova data de renovação: ${brazilTime.toISO()}`);
            } else {
                console.log(`Ainda não é hora de renovar as palavras. Dias restantes: ${30 - diffInDays}`);
            }
        } else {
            console.error('Usuário não encontrado na tabela.');
        }
    } catch (error) {
        console.error('Erro ao verificar renovação de palavras:', error.message);
    }
};

// Função para iniciar a verificação no login e a cada 24 horas
export const startSync = () => {
    // Executa a verificação imediatamente no login
    verificarRenovacaoPalavras();

    // Define o intervalo para verificar a cada 24 horas
    syncInterval = setInterval(() => {
        verificarRenovacaoPalavras();
    }, 24 * 60 * 60 * 1000); // 24 horas
};

// Função para interromper a verificação de renovação de palavras
export const stopSync = () => {
    clearInterval(syncInterval); // Interrompe o intervalo definido na função startSync
};

// Adicione uma função para garantir que a verificação seja chamada no login
export const verificarNoLogin = () => {
    // Faz a verificação assim que o usuário faz o login
    verificarRenovacaoPalavras();
};
