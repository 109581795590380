import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './chatAdmin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faEnvelope, faTimes, faPaperPlane, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const ChatAdmin = () => {
  const [tickets, setTickets] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [erro, setErro] = useState('');
  const [loading, setLoading] = useState(true);
  const [mensagem, setMensagem] = useState('');
  const [ticketSelecionado, setTicketSelecionado] = useState(null);
  const [usuarioSelecionado, setUsuarioSelecionado] = useState(null);
  const [mensagemEnviada, setMensagemEnviada] = useState('');
  const baseURL = 'https://api.baserow.io/api/database/rows/table';

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = async () => {
    setLoading(true);
    try {
      const responseTickets = await axios.get(
        `${baseURL}/${process.env.REACT_APP_TABLE_ID_TICKETS_RELATORIO_SUPORTE}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RELATORIO_SUPORTE}`,
          },
        }
      );

      const usuariosUnicos = [...new Set(responseTickets.data.results
        .filter(ticket => ticket['Status'] === 'Em Aberto')
        .map(ticket => ticket['E-mail do Usuário']))];

      setUsuarios(usuariosUnicos);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar os usuários com tickets:', error);
      setErro('Erro ao buscar os usuários com tickets. Tente novamente mais tarde.');
      setLoading(false);
    }
  };

  const fetchTicketsDoUsuario = async (email) => {
    setLoading(true);
    setUsuarioSelecionado(email);
    try {
      const responseTickets = await axios.get(
        `${baseURL}/${process.env.REACT_APP_TABLE_ID_TICKETS_RELATORIO_SUPORTE}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RELATORIO_SUPORTE}`,
          },
        }
      );

      const userTickets = responseTickets.data.results.filter(
        (ticket) => ticket['E-mail do Usuário'] === email && ticket['Status'] === 'Em Aberto'
      );

      const ticketsWithInteractions = await Promise.all(
        userTickets.map(async (ticket) => {
          const interacoes = await fetchInteracoes(ticket.id, ticket['Categoria'], email);
          return { ...ticket, interacoes };
        })
      );

      setTickets(ticketsWithInteractions);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar os tickets do usuário:', error);
      setErro('Erro ao buscar os tickets do usuário. Tente novamente mais tarde.');
      setLoading(false);
    }
  };

  const fetchInteracoes = async (ticketId, categoria, email) => {
    try {
      const responseInteracoes = await axios.get(
        `${baseURL}/${process.env.REACT_APP_TABLE_ID_INTERACOES_RELATORIO_SUPORTE}/?user_field_names=true`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RELATORIO_SUPORTE}`,
          },
          params: {
            'filter__ID do Ticket__equal': ticketId,
            'filter__E-mail do Usuário__equal': email,
            'filter__Categoria__equal': categoria,
          },
        }
      );

      return responseInteracoes.data.results.map((interacao) => ({
        'Tipo de Interação': interacao['Tipo de Interação'],
        'Conteúdo da Mensagem': interacao['Conteúdo da Mensagem'],
        'Data de Envio': interacao['Data de Envio'],
      }));
    } catch (error) {
      console.error('Erro ao buscar interações:', error);
      return [];
    }
  };

  const handleMensagemChange = (e) => {
    setMensagem(e.target.value);
  };

  const handleEnviarMensagem = async (ticketId) => {
    if (!mensagem.trim()) return;

    try {
      await axios.post(
        `${baseURL}/${process.env.REACT_APP_TABLE_ID_INTERACOES_RELATORIO_SUPORTE}/?user_field_names=true`,
        {
          'ID do Ticket': ticketId,
          'E-mail do Usuário': usuarioSelecionado,
          'Tipo de Interação': 'Admin',
          'Conteúdo da Mensagem': mensagem,
          'Data de Envio': new Date().toISOString(),
          'Categoria': ticketSelecionado['Categoria'],
        },
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RELATORIO_SUPORTE}`,
          }
        }
      );
      
      await marcarComoLido(ticketId); // Marca a mensagem como lida
      setMensagem('');
      setMensagemEnviada('Mensagem enviada com sucesso!');
      fetchTicketsDoUsuario(usuarioSelecionado);

      // Após enviar para o banco de dados, enviar os dados ao webhook
      const numeroUsuario = await buscarNumeroUsuario(usuarioSelecionado);
      const nomeUsuario = await buscarNomeUsuario(usuarioSelecionado); // Nova consulta para buscar o nome do usuário
      enviarParaWebhook(numeroUsuario, ticketSelecionado['Categoria'], mensagem, nomeUsuario); // Envia o nome, categoria e mensagem
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error);
      setErro('Erro ao enviar a mensagem. Tente novamente mais tarde.');
    }
  };

 // Consulta o número do usuário baseado no e-mail
const buscarNumeroUsuario = async (email) => {
  try {
    const response = await axios.get(
      `${baseURL}/${process.env.REACT_APP_TABLE_ID_CADASTRO}/?user_field_names=true`,
      {
        headers: {
          Authorization: `Token ${process.env.REACT_APP_TOKEN_CONSULTA_NUMERO}`,
        },
        params: {
          'filter__E-mail__equal': email
        }
      }
    );
    return response.data.results[0]['Numero do Whatsapp'];
  } catch (error) {
    console.error('Erro ao buscar o número do usuário:', error);
    return null;
  }
};

// Consulta o nome do usuário baseado no e-mail
const buscarNomeUsuario = async (email) => {
  try {
    const response = await axios.get(
      `${baseURL}/${process.env.REACT_APP_TABLE_ID_CADASTRO}/?user_field_names=true`,
      {
        headers: {
          Authorization: `Token ${process.env.REACT_APP_TOKEN_CONSULTA_NUMERO}`,
        },
        params: {
          'filter__E-mail__equal': email
        }
      }
    );
    return response.data.results[0]['Nome']; // Assume que a coluna se chama 'Nome'
  } catch (error) {
    console.error('Erro ao buscar o nome do usuário:', error);
    return null;
  }
};

  // Envia os dados para o webhook
  const enviarParaWebhook = async (numero, categoria, mensagem, nome) => {
    try {
      await axios.post(process.env.REACT_APP_WEBHOOK_URL_CHAT_ADMIN, {
        numero,
        categoria,
        mensagem,
        nome
      });
      console.log('Dados enviados para o Webhook com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar os dados para o Webhook:', error);
    }
  };

  const marcarComoLido = async (ticketId) => {
    try {
      await axios.patch(
        `${baseURL}/${process.env.REACT_APP_TABLE_ID_TICKETS_RELATORIO_SUPORTE}/${ticketId}/?user_field_names=true`,
        {
          'Lida': true,
        },
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RELATORIO_SUPORTE}`,
          },
        }
      );
    } catch (error) {
      console.error('Erro ao marcar o ticket como lido:', error);
    }
  };

  const handleFecharTicket = async (ticketId) => {
    try {
      await axios.patch(
        `${baseURL}/${process.env.REACT_APP_TABLE_ID_TICKETS_RELATORIO_SUPORTE}/${ticketId}/?user_field_names=true`,
        {
          'Status': 'Finalizado',
          'Última Atualização': new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_BASEROW_API_TOKEN_RELATORIO_SUPORTE}`,
          }
        }
      );
      fetchTicketsDoUsuario(usuarioSelecionado);
    } catch (error) {
      console.error('Erro ao fechar o ticket:', error);
      setErro('Erro ao fechar o ticket. Tente novamente mais tarde.');
    }
  };

  const handleToggleTicket = (ticketId) => {
    setTicketSelecionado(ticketSelecionado === ticketId ? null : ticketId);
    setMensagemEnviada('');
  };

  return (
    <div className="chat-admin-container">
      <h2 className="chat-admin-titulo">Relatório de Suporte - Administração</h2>
      {erro && <p className="chat-admin-erro">{erro}</p>}
      {loading ? (
        <p>Carregando usuários e tickets...</p>
      ) : (
        <div className="usuarios-tickets-container">
          <div className="usuarios-list">
            <h3>Usuários com Tickets Abertos:</h3>
            <ul>
              {usuarios.map((usuario) => (
                <li key={usuario} onClick={() => fetchTicketsDoUsuario(usuario)}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {usuario}
                  {tickets.some(ticket => !ticket['Lida']) && (
                    <FontAwesomeIcon icon={faEnvelope} className="notificacao-icon" />
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="tickets-list">
            {tickets.length > 0 && (
              tickets.map((ticket) => (
                <div className="ticket-container" key={ticket.id}>
                  <div className="ticket-header" onClick={() => handleToggleTicket(ticket.id)}>
                    <FontAwesomeIcon icon={faFolderOpen} className="ticket-icon" />
                    <h3 className="ticket-categoria">{ticket.Categoria}</h3>
                    <FontAwesomeIcon icon={ticketSelecionado === ticket.id ? faChevronUp : faChevronDown} />
                  </div>
                  {ticketSelecionado === ticket.id && (
                    <div className="ticket-detalhes">
                      <p><strong>Assunto:</strong> {ticket.Assunto}</p>
                      <p><strong>Status:</strong> {ticket.Status}</p>
                      <p><strong>Última Atualização:</strong> {new Date(ticket['Última Atualização']).toLocaleString()}</p>
                      <div className="interacoes-container">
                        <h4>Interações:</h4>
                        {ticket.interacoes.length > 0 ? (
                          ticket.interacoes.map((interacao, index) => (
                            <div key={index} className="interacao">
                              <p><strong>{interacao['Tipo de Interação']}:</strong> {interacao['Conteúdo da Mensagem']}</p>
                              <p><em>{new Date(interacao['Data de Envio']).toLocaleString()}</em></p>
                            </div>
                          ))
                        ) : (
                          <p>Sem interações para este ticket.</p>
                        )}
                      </div>
                      <div className="nova-mensagem-container">
                        <textarea
                          className="input-mensagem"
                          placeholder="Digite sua mensagem..."
                          value={mensagem}
                          onChange={handleMensagemChange}
                        ></textarea>
                        <button 
                          className="button-enviar" 
                          onClick={() => handleEnviarMensagem(ticket.id)}
                          disabled={!mensagem.trim()}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                        </button>
                        {mensagemEnviada && <p className="mensagem-sucesso">{mensagemEnviada}</p>}
                      </div>
                      <button className="button-fechar-ticket" onClick={() => handleFecharTicket(ticket.id)}>
                        <FontAwesomeIcon icon={faTimes} /> Fechar Ticket
                      </button>
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAdmin;
